<template>
  <div class="filters_wrap">
    <client-only>
      <div v-if="!isTabletOrMobile" class="teaser_divider">
        Быстрый подбор
      </div>
      <form id="smartFilterForm" class="catalog_request is-dynamic">
        <ul class="catalog_options catalog_options__primary">
          <li class="catalog_option catalog_option__styles">
            <multiselect
              v-model="selectedStyles"
              multiple
              :options="currentStylesList"
              :show-labels="false"
              :clear-on-select="true"
              :close-on-select="true"
              track-by="name"
              label="name"
              :disabled="!currentStylesList.length"
              :searchable="true"
              :allow-empty="true"
              :option-height="38"
              :placeholder="styleListPlaceholder"
              :limit-text="limitTextStyles"
              @open="styleListOpened = true"
              @close="styleListOpened = false"
              @input="filterChangeHandler"
            >
              <span slot="noResult">Поиск не дал результатов</span>
            </multiselect>
          </li>
          <li class="catalog_option catalog_option__brands">
            <multiselect
              v-model="selectedBrand"
              :options="currentBrandsList"
              :disabled="!currentBrandsList.length"
              :show-labels="false"
              :clear-on-select="false"
              track-by="name"
              label="name_eng"
              :searchable="false"
              :allow-empty="false"
              :option-height="38"
              placeholder="Все бренды"
              @input="filterChangeHandler"
            />
          </li>
          <li class="catalog_option">
            <div class="catalog_filter">
              <input id="catalog-min" v-model="priceRangeMin" type="text">
              <input id="catalog-max" v-model="priceRangeMax" type="text">
              <vue-slider
                v-model="priceRange"
                :dot-size="10"
                :interval="5000"
                :min-range="1000"
                :drag-on-click="true"
                :silent="true"
                :adsorb="true"
                :tooltip-formatter="numberFormat"
                :min="currentCategoryMinPrice"
                :max="currentCategoryMaxPrice"
                @drag-end="sliderDragEndHandler"
              />
            </div>
          </li>
          <li class="catalog_option catalog_option__color">
            <multiselect
              v-model="selectedColor"
              :options="colorsWithEmpty"
              :show-labels="false"
              :clear-on-select="false"
              track-by="name"
              label="name"
              :searchable="false"
              :allow-empty="false"
              :option-height="38"
              placeholder="Все цвета"
              @input="filterChangeHandler"
            >
              <template slot="singleLabel" slot-scope="props">
                <div
                  class="option__color"
                  :style="{ backgroundColor: props.option.code, color: props.option.contrastCode }"
                >
                  <span v-if="showSelectedColor">{{ props.option.name }}</span>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div
                  class="option__color"
                  :style="{ backgroundColor: props.option.code, color: props.option.contrastCode }"
                >
                  {{ props.option.name }} {{ props.option.count ? `(${props.option.count})` : '' }}
                </div>
              </template>
            </multiselect>
          </li>
          <li class="catalog_option">
            <button v-if="!(filterChanged || isApplyFilters)" class="catalog_query" type="submit" @click.prevent>
              ← фильтр
            </button>
            <button
              v-else-if="filterChanged && foundItemsCount === 0"
              class="catalog_query is-reset"
              type="submit"
              @click.prevent
            >
              Не найдено
            </button>
            <button
              v-else-if="filterChanged && foundItemsCount > 0"
              class="catalog_query is-active"
              type="submit"
              @click.prevent="applyFilter"
            >
              <span>{{ foundItemsCount }}</span>
              показать
            </button>
            <button
              v-else-if="isApplyFilters"
              class="catalog_query is-reset"
              type="submit"
              name="resetFilter"
              value="Y"
              @click.prevent="resetFilter"
            >
              Сбросить
            </button>
          </li>
        </ul>
      </form>
    </client-only>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash.debounce'
import { app } from 'assets/js/common'
import {
  cleanObjectFromNull,
  getMappedBrandsGetterName, getMappedColorsGetterName,
  getMappedStylesGetterName
} from '@/helpers/utils'
import { cookieStore } from '@/assets/js/storejs'
import { LAST_CATALOG_URL } from '@/assets/js/constants'

export default {
  name: 'Filters',
  props: {
    showSelectedColor: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      foundItemsCount: 0,
      selectedColor: null,
      selectedStyles: [],
      selectedBrand: null,
      priceRangeMin: 0,
      priceRangeMax: 100000,
      priceRange: null,
      countedJson: null,
      debounceMethod: null,
      silentUpdating: false,
      styleListOpened: false
    }
  },
  computed: {
    ...mapState({
      currentBrand: state => state.currentBrand,
      currentCategory: state => state.currentCategory,
      currentMinPrice: state => state.currentMinPrice,
      currentMaxPrice: state => state.currentMaxPrice,
      currentCategoryMinPrice: state => state.currentCategoryMinPrice,
      currentCategoryMaxPrice: state => state.currentCategoryMaxPrice,
      currentStyles: state => state.currentStyles,
      currentColor: state => state.currentColor
    }),
    ...mapGetters({
      isApplyFilters: 'isApplyFilters'
    }),
    searchQuery () {
      return this.$store.state.searchQuery
    },
    styleListPlaceholder () {
      return this.styleListOpened ? 'Выберите стиль' : 'Все стили'
    },
    currentBrandsList () {
      const result = this.$store.getters[getMappedBrandsGetterName(this.currentCategory.slug)]
      return result && result.length ? [{ name_eng: 'Все бренды' }].concat(result) : []
    },
    currentStylesList () {
      const result = this.$store.getters[getMappedStylesGetterName(this.currentCategory.slug)]
      return result && result.length ? result : []
    },
    colorsWithEmpty () {
      const filterColors = this.$store.state.filterColors
      let result = this.$store.getters[getMappedColorsGetterName(this.currentCategory.slug)]
      result = result.filter((color) => {
        return filterColors.some(fColor => color.id === fColor.id)
      })

      result = result.map((colorItem) => {
        colorItem.count = filterColors.find(item => item.id === colorItem.id)?.items || null
        return colorItem
      }).sort((color1, color2) => color2.count - color1.count)

      return result && result.length ? [{ name: 'Все цвета', code: null }].concat(result) : []
    },
    filterChanged () {
      const priceMinCondition = this.currentMinPrice || this.currentCategoryMinPrice
      const priceMaxCondition = this.currentMaxPrice || this.currentCategoryMaxPrice
      let currentStylesIds = (this.currentStyles && this.currentStyles.length && this.currentStyles.map(style => style.id)) || []
      let selectedStylesIds = (this.selectedStyles && this.selectedStyles.length && this.selectedStyles.map(style => style.id)) || []
      currentStylesIds.sort()
      selectedStylesIds.sort()
      currentStylesIds = currentStylesIds.join('')
      selectedStylesIds = selectedStylesIds.join('')

      return (
        (this.currentColor && this.currentColor !== this.selectedColor) ||
        (this.currentStyles && this.currentStyles.length && currentStylesIds !== selectedStylesIds) ||
        (this.currentBrand && this.currentBrand !== this.selectedBrand) ||
        (this.selectedStyles && this.selectedStyles.length && !(this.currentStyles && this.currentStyles.length)) ||
        (this.selectedColor && !this.currentColor) ||
        (this.selectedBrand && !this.currentBrand) ||
        this.priceRangeMin !== priceMinCondition ||
        this.priceRangeMax !== priceMaxCondition
      )
    }
  },
  watch: {
    async currentBrand () {
      await this.updateDependencies()
    },
    async currentColor () {
      await this.updateDependencies()
    },
    async currentStyles () {
      await this.updateDependencies()
    },
    async currentCategoryMaxPrice () {
      await this.updateDependencies()
    },
    async currentMinPrice () {
      await this.updateDependencies()
    },
    async currentMaxPrice () {
      await this.updateDependencies()
    },
    priceRangeMin () {
      const copy = this.priceRange.concat()
      copy[0] = Math.min(Math.max(this.priceRangeMin, this.currentCategoryMinPrice), this.priceRangeMax)
      this.priceRange = copy
      if (!this.silentUpdating) {
        this.debounceMethod()
      }
    },
    priceRangeMax () {
      const copy = this.priceRange.concat()
      copy[1] = Math.max(
        Math.min(this.priceRangeMax, this.currentCategoryMaxPrice),
        this.currentCategoryMinPrice + 1,
        this.priceRangeMin
      )
      this.priceRange = copy

      if (!this.silentUpdating) {
        this.debounceMethod()
      }
    }
  },
  created () {
    this.updateDependencies()
    this.debounceMethod = debounce(() => {
      this.filterChangeHandler()
    }, 1200)
  },
  methods: {
    limitTextStyles (count) {
      return `+ ${count}`
    },
    numberFormat (val) {
      const ar = val
        .toString()
        .split('')
        .reverse()
      ar.splice(3, 0, ' ')
      return ar.reverse().join('')
    },

    sliderDragEndHandler () {
      this.priceRangeMin = this.priceRange[0]
      this.priceRangeMax = this.priceRange[1]
      this.filterChangeHandler()
    },

    updateDependencies () {
      this.silentUpdating = true

      this.selectedBrand = this.currentBrand
      this.selectedColor = this.currentColor
      this.selectedStyles = this.currentStyles

      this.priceRangeMin = this.currentMinPrice || this.currentCategoryMinPrice
      this.priceRangeMax = this.currentMaxPrice || this.currentCategoryMaxPrice

      const sliderMinValue = Math.min(Math.max(this.priceRangeMin, this.currentCategoryMinPrice), this.priceRangeMax)
      const sliderMaxValue = Math.max(
        Math.min(this.priceRangeMax, this.currentCategoryMaxPrice),
        this.currentCategoryMinPrice + 1,
        this.priceRangeMin
      )
      this.priceRange = [sliderMinValue, sliderMaxValue]

      this.silentUpdating = false
    },
    async filterChangeHandler () {
      const filterJson = {
        categorySlug: this.currentCategory.slug
      }

      if (this.selectedBrand && this.selectedBrand.slug) {
        filterJson.brandSlug = this.selectedBrand.slug
      } else {
        this.selectedBrand = null
      }
      if (this.selectedStyles && this.selectedStyles.length) {
        filterJson.styleSlugs = this.selectedStyles.map(style => style.slug)
      } else {
        this.selectedStyles = []
      }
      if (this.selectedColor && this.selectedColor.code) {
        filterJson.colorId = this.selectedColor.id
      } else {
        this.selectedColor = null
      }

      if (
        (this.priceRangeMin !== null || this.priceRangeMax !== null) &&
          (this.priceRangeMin !== this.currentCategoryMinPrice || this.priceRangeMax !== this.currentCategoryMaxPrice)
      ) {
        filterJson.priceMin = this.priceRangeMin || 0
        filterJson.priceMax = this.priceRangeMax || 1
      } else {
        this.priceRangeMin = this.currentCategoryMinPrice
        this.priceRangeMax = this.currentCategoryMaxPrice
      }

      const selectedStylesIds = (this.selectedStyles && this.selectedStyles.length && this.selectedStyles.map(style => style.id)) || []

      const filterData = {
        categoryId: this.currentCategory?.id,
        brandId: this.selectedBrand?.id,
        colorId: this.selectedColor?.id,
        stylesId: selectedStylesIds,
        priceMin: this.priceRangeMin,
        priceMax: this.priceRangeMax,
        searchNameQuery: this.searchQuery
      }

      const dataRequest = cleanObjectFromNull(filterData)
      await this.$store.dispatch('getFilters', dataRequest)
      await this.getFilteredItemsCount(filterJson)
    },
    async getFilteredItemsCount (filterJson) {
      if (this.countedJson && JSON.stringify(this.countedJson) === JSON.stringify(filterJson)) {
        return
      }

      try {
        this.foundItemsCount = await this.$store.dispatch('countItems', filterJson)
        this.countedJson = filterJson
      } catch (error) {
        this.$sentry.captureException(error)
        // eslint-disable-next-line no-console
        console.error('Error', error.message)
      }
    },
    isTabletOrMobile () {
      return app.isIpadDesktop || app.tablet || app.mobile
    },
    applyFilter () {
      let url = `/${this.currentCategory.slug}/`
      // eslint-disable-next-line no-prototype-builtins
      if (this.countedJson.hasOwnProperty('brandSlug')) {
        url += `${this.countedJson.brandSlug}/`
      }
      const urlParams = new URLSearchParams()
      // eslint-disable-next-line no-prototype-builtins
      if (this.countedJson.hasOwnProperty('styleSlugs')) {
        for (const slug of this.countedJson.styleSlugs) {
          urlParams.append('style', slug)
        }
      }

      // eslint-disable-next-line no-prototype-builtins
      if (this.countedJson.hasOwnProperty('colorId')) {
        urlParams.append('color', this.countedJson.colorId)
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.countedJson.hasOwnProperty('priceMin') && this.countedJson.hasOwnProperty('priceMax')) {
        urlParams.append('price_min', this.countedJson.priceMin)
        urlParams.append('price_max', this.countedJson.priceMax)
      }

      url += '?' + urlParams.toString()

      try {
        cookieStore.set(LAST_CATALOG_URL, url)
      } catch (error) {
        this.$sentry.captureException(error)
        // возможно приватный режим
        // eslint-disable-next-line no-console
        console.error('Error LAST_CATALOG_URL cookie', error)
      }

      this.$emit('applyed')
      this.$router.push(url)

      this.countedJson = null
      this.foundItemsCount = 0
    },

    resetFilter () {
      const url = `/${this.currentCategory.slug}/`
      this.selectedBrand = null
      this.selectedStyles = []
      this.selectedColor = null
      this.priceRangeMin = this.currentCategoryMinPrice
      this.priceRangeMax = this.currentCategoryMaxPrice
      this.countedJson = null
      this.foundItemsCount = 0
      this.$emit('close')

      try {
        cookieStore.set(LAST_CATALOG_URL, url)
      } catch (error) {
        this.$sentry.captureException(error)
        // возможно приватный режим
        // eslint-disable-next-line no-console
        console.error('Error LAST_CATALOG_URL cookie', error)
      }
      this.$router.push(url)
    }
  }
}
</script>

<style lang="scss">
.filters_wrap {
  position: relative;
  z-index: 450;

  .multiselect,
  .multiselect__input,
  .multiselect__single,
  .multiselect__option span {
    font: bold 14px/30px 'ProxyB', Arial, Helvetica, sans-serif;
    color: #666;
    text-transform: uppercase;
  }

  .multiselect:hover {
    .multiselect__select:before {
      border-top-color: #000;
    }
    .multiselect__placeholder,
    .multiselect__input,
    .multiselect__single,
    .multiselect__option span {
      color: #000;
    }
  }

  .multiselect {
    cursor: pointer;
    min-height: 38px;

    &.multiselect--disabled {
      background-color: unset;
    }

    .multiselect__select:before {
      position: inherit;
      right: unset;
      top: unset;
      margin-top: 1px;
    }

    &.multiselect--active {
      .multiselect__select {
        transform: none;
      }
      .multiselect__select:before {
        transform: rotate(180deg);
      }
    }

    .multiselect__select {
      transition: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      padding: 0;
      border-left: 1px solid #e7e7e7;
      border-right: 0;
      z-index: 1;
      height: 100%;
      min-height: 38px;
    }

    .multiselect__option {
      padding: 5px 10px 5px 6px;
      min-height: 38px;
      &--highlight {
        background: #ffdbe1;
      }
      &--selected {
        background: #ffb3c1;
      }
    }

    .multiselect__option--selected.multiselect__option--highlight {
      color: #666;
    }

    .multiselect__placeholder {
      color: #666;
      margin-bottom: unset;
      padding-top: unset;
      padding-left: 6px;
    }

    .multiselect__content-wrapper {
      overflow-x: hidden;
    }

    .multiselect__tags {
      border-radius: unset;
      padding: 5px 35px 0 6px;
      min-height: 38px;
    }

    .multiselect__single {
      margin-bottom: unset;
      max-height: 38px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .catalog_option__styles {
    .multiselect {
      .multiselect__tags {
        padding: 3px 35px 0 6px;
        min-height: 38px;
        line-height: 14px;

        .multiselect__placeholder {
          line-height: 30px;
        }

        .multiselect__input {
          margin-bottom: 0;
        }

        .multiselect__strong {
          margin-bottom: unset;
          display: unset;
          font-size: 22px;
          line-height: 30px;
        }

        .multiselect__tag {
          margin-bottom: 0;
          padding: 8px 26px 8px 10px;
          background: #fa809d;
          max-width: 180px;

          span {
            color: #fff;
          }

          .multiselect__tag-icon {
            line-height: 24px;
            width: 23px;
            padding-top: 4px;
            margin-left: 0;
            padding-left: 5px;

            &:focus, &:hover {
              background: #d2647b;
            }
          }

          .multiselect__tag-icon:after {
            font-size: 22px;
            color: #fff;
          }
        }
      }
    }
  }

  .catalog_option__color {
    .multiselect {
      .multiselect__tags {
        padding: 0 35px 0 0;
      }
      .multiselect__single {
        padding: 0;
        height: 36px;
        border-radius: 0;
        max-height: 36px;
      }

      .multiselect__option--highlight {
        font-weight: bold;
      }

      .option__color {
        height: 100%;
        padding: 11px 4px 5px 4px;
        font: 14px/17px 'ProxyR', Arial, Helvetica, sans-serif;
      }

      .multiselect__option {
        padding: 0;
        min-height: 38px;
        height: 38px;
      }

      .multiselect__placeholder {
        height: 36px;
        line-height: 38px;
      }
    }
  }
}

.mobile-filters {
  .filters_wrap {
    .catalog_option__color {
      .option__color {
        font: bold 14px/30px 'ProxyB', Arial, Helvetica, sans-serif;
        color: #666;
        text-transform: uppercase;
        padding: 5px 10px 5px 6px;
      }
    }
  }
}

/* Set the theme color of the component */
$themeColor: #f8819e;
/* import theme style */
@import '~vue-slider-component/lib/theme/default.scss';

.vue-slider {
  margin-top: 10px;
}

@media (hover: none) and (pointer: coarse), only screen and (max-width: 1007px) {
  @media (max-width: 1020px) {
    .vue-slider {
      max-width: 95%;
      margin-left: 2.5%;
    }
  }
}

.catalog_filter {
  #catalog-min,
  #catalog-max {
    padding-right: unset;
    padding-left: unset;
    text-align: center;
    text-align-last: center;
  }
}
</style>
