import { app } from '@/assets/js/common'

export default {
  methods: {
    pushEvent ({ event }) {
      if (this.$ga) {
        this.$ga.event({
          eventCategory: event
        })
      }
      // eslint-disable-next-line no-undef
      if (window.ym) {
        // eslint-disable-next-line no-undef
        window.ym(24933563, 'reachGoal', event)
      } else if (window.yaCounter24933563) {
        // eslint-disable-next-line no-undef
        window.yaCounter24933563.reachGoal(event)
      }

      // eslint-disable-next-line no-prototype-builtins
      if (window.dataLayer && window.dataLayer.hasOwnProperty('length')) {
        window.dataLayer.push(event)
      } else {
        window.dataLayer = []
        window.dataLayer.push(event)
      }
    },
    async toggleFavorite (id) {
      const flag = await this.$store.dispatch('favorite/toggle', id)
      if (!flag) {
        app.popup(
          'popup popup__texture popup__alert popup__fav-remove',
          'Товар удален из любимого',
          1500,
          'popup__fav-remove'
        )
        this.$emit('favorite-remove', id)
      } else {
        this.pushEvent({
          event: 'ADD_TO_FAVORITE'
        })
        app.popup('popup popup__texture popup__fav', 'Товар добавлен<br>в любимое!', 1500, 'popup__fav')
      }
    }
  }
}
