import Vue from 'vue'

const context = {
  fromBuffer: false
}

Vue.directive('phone', {
  bind (el, binding, vnode) {
    function replaceNumberForInput (value) {
      const regx = /(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,1})/
      const getMatch = (inputValue) => {
        return inputValue.replace(/\D/g, '').match(regx)
      }
      let x = getMatch(value)

      // eslint-disable-next-line no-prototype-builtins
      if (x.length && x[6] && x[6].length) {
        const numStringArray = value.split('')
        numStringArray.splice(4, 1)
        x = getMatch(numStringArray.join(''))
      }

      if (value) {
        if (value === '+') {
          return value
        }

        if (x[1] !== '7' && x[1] !== '8') {
          if (x[2] && x[0].length !== 11) {
            x = getMatch('+7' + value)
          } else {
            x[2] = x[1] + x[2]
          }
        }

        return !x[3]
          ? '+7 (' + x[2]
          : '+7 (' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : '') + (x[5] ? '-' + x[5] : '')
      }

      return ''
    }

    el.onkeydown = function (event) {
      const { key } = event

      if (key === 'Backspace' || key === 'Delete') {
        if (el.value === '+7 (') {
          this.value = ''
          vnode.context[binding.expression] = ''
        }
      }
    }

    el.oninput = function (e) {
      if (!e.isTrusted) {
        return
      }
      const positions = getInputSelection(el)
      const hasClosedBracket = this.value.includes(')')
      const hasSecondTire = this.value.includes('-', 13)
      const hasFirstTire = this.value.includes('-', 11) || hasSecondTire
      const isStart = this.value.length < 5
      const notEightAndSeven = this.value !== '8' && this.value !== '+' && this.value !== '7' && this.value !== '+7'

      this.value = replaceNumberForInput(this.value)

      if (this.value === '+7 (' || (notEightAndSeven && isStart)) {
        positions.end = 4 + (notEightAndSeven ? 1 : 0)
      }
      if (!hasClosedBracket && this.value.includes(')')) {
        positions.end += 2
      }

      if (!hasFirstTire && this.value.includes('-') && !this.value.includes('-', 13)) {
        positions.end += 2
      }

      if (!hasSecondTire && this.value.includes('-', 13)) {
        positions.end += 2
      }
      vnode.context[binding.expression] = this.value

      if (context.fromBuffer) {
        setCaretPosition(el, 20)
        context.fromBuffer = false
      } else {
        setCaretPosition(el, positions.end)
      }
    }

    el.onpaste = function () {
      context.fromBuffer = true
    }
  }
})

// eslint-disable-next-line no-unused-vars
function setCaretPosition (ctrl, pos) {
  // Modern browsers
  if (ctrl.setSelectionRange) {
    ctrl.focus()
    ctrl.setSelectionRange(pos, pos)

    // IE8 and below
  } else if (ctrl.createTextRange) {
    const range = ctrl.createTextRange()
    range.collapse(true)
    range.moveEnd('character', pos)
    range.moveStart('character', pos)
    range.select()
  }
}

function getInputSelection (el) {
  let start = 0; let end = 0; let normalizedValue; let range
  let textInputRange; let len; let endRange

  if (typeof el.selectionStart === 'number' && typeof el.selectionEnd === 'number') {
    start = el.selectionStart
    end = el.selectionEnd
  } else {
    range = document.selection.createRange()

    if (range && range.parentElement() === el) {
      len = el.value.length
      normalizedValue = el.value.replace(/\r\n/g, '\n')

      // Create a working TextRange that lives only in the input
      textInputRange = el.createTextRange()
      textInputRange.moveToBookmark(range.getBookmark())

      // Check if the start and end of the selection are at the very end
      // of the input, since moveStart/moveEnd doesn't return what we want
      // in those cases
      endRange = el.createTextRange()
      endRange.collapse(false)

      if (textInputRange.compareEndPoints('StartToEnd', endRange) > -1) {
        start = end = len
      } else {
        start = -textInputRange.moveStart('character', -len)
        start += normalizedValue.slice(0, start).split('\n').length - 1

        if (textInputRange.compareEndPoints('EndToEnd', endRange) > -1) {
          end = len
        } else {
          end = -textInputRange.moveEnd('character', -len)
          end += normalizedValue.slice(0, end).split('\n').length - 1
        }
      }
    }
  }

  return {
    start,
    end
  }
}
