export default function (hexcolor) {
  if (!hexcolor) {
    return 'black'
  }

  const baseColor = hexcolor.replace('#', '')
  const r = parseInt(baseColor.substr(0, 2), 16)
  const g = parseInt(baseColor.substr(2, 2), 16)
  const b = parseInt(baseColor.substr(4, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'black' : 'white'
}
