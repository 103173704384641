var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"container",class:{
    'with-placeholder': _vm.usePlaceholder
  },style:(_vm.usePlaceholder ? { paddingTop: '150%' } : null)},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
      src: _vm.src,
      loading: '/img/preloader.svg',
      throttleWait: 5
    }),expression:"{\n      src: src,\n      loading: '/img/preloader.svg',\n      throttleWait: 5\n    }"}],ref:"img",staticClass:"zoom-image",attrs:{"src":"/img/preloader.svg","data-zoom":_vm.zoomSrc,"alt":_vm.alt,"itemprop":"image"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }