export const CATEGORY = Object.freeze({
  ALL: 'all',
  WEDDING: 'wedding',
  EVENING: 'evening',
  PROM: 'prom',
  SALE: 'sale',
  BOUDOIR: 'boudoir',
  ACCESSORIES: 'accessories',
  OUTWEAR: 'outerwear'
})

export const SEO_TYPES = {
  ITEM: 'item',
  ITEM_STYLE: 'item_style',
  CATEGORY: 'category',
  CATEGORY_STYLE: 'category_style',
  CATEGORY_BRAND: 'category_brand',
  CATEGORY_BRAND_STYLE: 'category_brand_style',
  CATEGORY_PRICE: 'category_price',
  PAGE: 'page'
}

export const SEO_CATALOG_HIARCHY = [SEO_TYPES.CATEGORY_BRAND_STYLE, SEO_TYPES.CATEGORY_STYLE, SEO_TYPES.CATEGORY_BRAND, SEO_TYPES.CATEGORY]

export const SEO_ENTITIES = {
  BRANDS: 'brands',
  CATEGORIES: 'categories',
  ARTICLE: 'article',
  ITEMS: 'items',
  ITEMSANDSTYLE: 'itemandstyle',
  MAINPAGE: 'mainpage',
  ARTICLES: 'articles',
  REVIEWS: 'reviews',
  CONTACTS: 'contacts',
  STYLES: 'styles',
  BRANDSTYLE: 'brandstyle',
  DELIVERY: 'delivery',
  PRICESTYLE: 'pricestyle'
}

export const IMAGE_URL = process.env.imageURL
export const CLIENT_URL = process.env.clientURL

export const RETURN_LINK_CLICKED = 'returnLinkClicked'
export const LAST_CATALOG_URL = 'lastCatalogURL'
export const LAST_CATALOG_VERTICAL = 'lastCatalogVertical'

export const CLOSE_OFFCANVAS_EVENT = 'close-canvas-event'
