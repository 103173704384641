/* eslint-disable */
/**
 * jScrollPane v2.0.14
 * http://github.com/vitch/jScrollPane
 */
(function(b,ra,s){b.fn.jScrollPane=function(q){function Ba(c,q){function la(a){var d,z,r,ma,w,R=!1,S=!1;e=a;if(f===s)ma=c.scrollTop(),w=c.scrollLeft(),c.css({overflow:"hidden",padding:0}),j=c.innerWidth()+O,h=c.innerHeight(),c.width(j),f=b('<div class="jspPane" />').css("padding",ta).append(c.children()),g=b('<div class="jspContainer" />').css({width:j+"px",height:h+"px"}).append(f).appendTo(c);else{c.css("width","");if(R=e.stickToBottom)R=p-h,R=20<R&&10>R-D();if(S=e.stickToRight)S=n-j,S=20<S&&10>
S-E();if(r=c.innerWidth()+O!=j||c.outerHeight()!=h)j=c.innerWidth()+O,h=c.innerHeight(),g.css({width:j+"px",height:h+"px"});if(!r&&ua==n&&f.outerHeight()==p){c.width(j);return}ua=n;f.css("width","");c.width(j);g.find(">.jspVerticalBar,>.jspHorizontalBar").remove().end()}f.css("overflow","auto");n=a.contentWidth?a.contentWidth:f[0].scrollWidth;p=f[0].scrollHeight;f.css("overflow","");na=n/j;da=p/h;x=1<da;v=1<na;if(!v&&!x)c.removeClass("jspScrollable"),f.css({top:0,width:g.width()-O}),g.unbind(oa),
f.find(":input,a").unbind("focus.jsp"),c.attr("tabindex","-1").removeAttr("tabindex").unbind("keydown.jsp keypress.jsp"),va();else{c.addClass("jspScrollable");if(a=e.maintainPosition&&(m||k))d=E(),z=D();x&&(g.append(b('<div class="jspVerticalBar" />').append(b('<div class="jspCap jspCapTop" />'),b('<div class="jspTrack" />').append(b('<div class="jspDrag" />').append(b('<div class="jspDragTop" />'),b('<div class="jspDragBottom" />'))),b('<div class="jspCap jspCapBottom" />'))),ea=g.find(">.jspVerticalBar"),
A=ea.find(">.jspTrack"),t=A.find(">.jspDrag"),e.showArrows&&(Z=b('<a class="jspArrow jspArrowUp" />').bind("mousedown.jsp",F(0,-1)).bind("click.jsp",T),$=b('<a class="jspArrow jspArrowDown" />').bind("mousedown.jsp",F(0,1)).bind("click.jsp",T),e.arrowScrollOnHover&&(Z.bind("mouseover.jsp",F(0,-1,Z)),$.bind("mouseover.jsp",F(0,1,$))),wa(A,e.verticalArrowPositions,Z,$)),G=h,g.find(">.jspVerticalBar>.jspCap:visible,>.jspVerticalBar>.jspArrow").each(function(){G-=b(this).outerHeight()}),t.hover(function(){t.addClass("jspHover")},
function(){t.removeClass("jspHover")}).bind("mousedown.jsp",function(a){b("html").bind("dragstart.jsp selectstart.jsp",T);t.addClass("jspActive");var d=a.pageY-t.position().top;b("html").bind("mousemove.jsp",function(a){P(a.pageY-d,!1)}).bind("mouseup.jsp mouseleave.jsp",xa);return!1}),ya());v&&(g.append(b('<div class="jspHorizontalBar" />').append(b('<div class="jspCap jspCapLeft" />'),b('<div class="jspTrack" />').append(b('<div class="jspDrag" />').append(b('<div class="jspDragLeft" />'),b('<div class="jspDragRight" />'))),
b('<div class="jspCap jspCapRight" />'))),fa=g.find(">.jspHorizontalBar"),B=fa.find(">.jspTrack"),u=B.find(">.jspDrag"),e.showArrows&&(aa=b('<a class="jspArrow jspArrowLeft" />').bind("mousedown.jsp",F(-1,0)).bind("click.jsp",T),ba=b('<a class="jspArrow jspArrowRight" />').bind("mousedown.jsp",F(1,0)).bind("click.jsp",T),e.arrowScrollOnHover&&(aa.bind("mouseover.jsp",F(-1,0,aa)),ba.bind("mouseover.jsp",F(1,0,ba))),wa(B,e.horizontalArrowPositions,aa,ba)),u.hover(function(){u.addClass("jspHover")},
function(){u.removeClass("jspHover")}).bind("mousedown.jsp",function(a){b("html").bind("dragstart.jsp selectstart.jsp",T);u.addClass("jspActive");var d=a.pageX-u.position().left;b("html").bind("mousemove.jsp",function(a){U(a.pageX-d,!1)}).bind("mouseup.jsp mouseleave.jsp",xa);return!1}),y=g.innerWidth(),za());if(v&&x){r=B.outerHeight();var Aa=A.outerWidth();G-=r;b(fa).find(">.jspCap:visible,>.jspArrow").each(function(){y+=b(this).outerWidth()});y-=Aa;h-=Aa;j-=r;B.parent().append(b('<div class="jspCorner" />').css("width",
r+"px"));ya();za()}v&&f.width(g.outerWidth()-O+"px");p=f.outerHeight();da=p/h;v&&(H=Math.ceil(1/na*y),H>e.horizontalDragMaxWidth?H=e.horizontalDragMaxWidth:H<e.horizontalDragMinWidth&&(H=e.horizontalDragMinWidth),u.width(H+"px"),I=y-H,pa(k));x&&(J=Math.ceil(1/da*G),J>e.verticalDragMaxHeight?J=e.verticalDragMaxHeight:J<e.verticalDragMinHeight&&(J=e.verticalDragMinHeight),t.height(J+"px"),C=G-J,qa(m));a&&(V(S?n-j:d,!1),K(R?p-h:z,!1));f.find(":input,a").unbind("focus.jsp").bind("focus.jsp",function(a){ga(a.target,
!1)});g.unbind(oa).bind(oa,function(a,d,b,c){a=k;d=m;l.scrollBy(b*e.mouseWheelSpeed,-c*e.mouseWheelSpeed,!1);return a==k&&d==m});var q,sa,L,M,ca,N=!1;g.unbind("touchstart.jsp touchmove.jsp touchend.jsp click.jsp-touchclick").bind("touchstart.jsp",function(a){a=a.originalEvent.touches[0];q=E();sa=D();L=a.pageX;M=a.pageY;ca=!1;N=!0}).bind("touchmove.jsp",function(a){if(N){a=a.originalEvent.touches[0];var d=k,b=m;l.scrollTo(q+L-a.pageX,sa+M-a.pageY);ca=ca||5<Math.abs(L-a.pageX)||5<Math.abs(M-a.pageY);
return d==k&&b==m}}).bind("touchend.jsp",function(){N=!1}).bind("click.jsp-touchclick",function(){if(ca)return ca=!1});if(e.enableKeyboardNavigation){var Q=function(){var a=k,d=m;switch(W){case 40:l.scrollByY(e.keyboardSpeed,!1);break;case 38:l.scrollByY(-e.keyboardSpeed,!1);break;case 34:case 32:l.scrollByY(h*e.scrollPagePercent,!1);break;case 33:l.scrollByY(-h*e.scrollPagePercent,!1);break;case 39:l.scrollByX(e.keyboardSpeed,!1);break;case 37:l.scrollByX(-e.keyboardSpeed,!1)}return ha=a!=k||d!=
m},W,ha,ia=[];v&&ia.push(fa[0]);x&&ia.push(ea[0]);f.focus(function(){c.focus()});c.attr("tabindex",0).unbind("keydown.jsp keypress.jsp").bind("keydown.jsp",function(a){if(!(a.target!==this&&(!ia.length||!b(a.target).closest(ia).length))){var d=k,e=m;switch(a.keyCode){case 40:case 38:case 34:case 32:case 33:case 39:case 37:W=a.keyCode;Q();break;case 35:K(p-h);W=null;break;case 36:K(0),W=null}ha=a.keyCode==W&&d!=k||e!=m;return!ha}}).bind("keypress.jsp",function(a){a.keyCode==W&&Q();return!ha});e.hideFocus?
(c.css("outline","none"),"hideFocus"in g[0]&&c.attr("hideFocus",!0)):(c.css("outline",""),"hideFocus"in g[0]&&c.attr("hideFocus",!1))}e.clickOnTrack&&(va(),x&&A.bind("mousedown.jsp",function(a){if(a.originalTarget===s||a.originalTarget==a.currentTarget){var d=b(this),c=d.offset(),z=a.pageY-c.top-m,r,g=!0,f=function(){var b=d.offset(),b=a.pageY-b.top-J/2,c=h*e.scrollPagePercent,w=C*c/(p-h);if(0>z)m-w>b?l.scrollByY(-c):P(b);else if(0<z)m+w<b?l.scrollByY(c):P(b);else{j();return}r=setTimeout(f,g?e.initialDelay:
e.trackClickRepeatFreq);g=!1},j=function(){r&&clearTimeout(r);r=null;b(document).unbind("mouseup.jsp",j)};f();b(document).bind("mouseup.jsp",j);return!1}}),v&&B.bind("mousedown.jsp",function(a){if(a.originalTarget===s||a.originalTarget==a.currentTarget){var d=b(this),c=d.offset(),z=a.pageX-c.left-k,r,g=!0,f=function(){var b=d.offset(),b=a.pageX-b.left-H/2,c=j*e.scrollPagePercent,h=I*c/(n-j);if(0>z)k-h>b?l.scrollByX(-c):U(b);else if(0<z)k+h<b?l.scrollByX(c):U(b);else{w();return}r=setTimeout(f,g?e.initialDelay:
e.trackClickRepeatFreq);g=!1},w=function(){r&&clearTimeout(r);r=null;b(document).unbind("mouseup.jsp",w)};f();b(document).bind("mouseup.jsp",w);return!1}}));a:if(location.hash&&1<location.hash.length){var ja,Y;d=escape(location.hash.substr(1));try{ja=b("#"+d+', a[name="'+d+'"]')}catch(ka){break a}ja.length&&f.find(d)&&(0===g.scrollTop()?Y=setInterval(function(){0<g.scrollTop()&&(ga(ja,!0),b(document).scrollTop(g.position().top),clearInterval(Y))},50):(ga(ja,!0),b(document).scrollTop(g.position().top)))}e.hijackInternalLinks&&
(b(document.body).data("jspHijack")||(b(document.body).data("jspHijack",!0),b(document.body).delegate("a[href*=#]","click",function(a){var d=this.href.substr(0,this.href.indexOf("#")),c=location.href,e;-1!==location.href.indexOf("#")&&(c=location.href.substr(0,location.href.indexOf("#")));if(d===c){d=escape(this.href.substr(this.href.indexOf("#")+1));e;try{e=b("#"+d+', a[name="'+d+'"]')}catch(z){return}e.length&&(d=e.closest(".jspScrollable"),c=d.data("jsp"),c.scrollToElement(e,!0),d[0].scrollIntoView&&
(c=b(ra).scrollTop(),e=e.offset().top,(e<c||e>c+b(ra).height())&&d[0].scrollIntoView()),a.preventDefault())}})))}e.autoReinitialise&&!X?X=setInterval(function(){la(e)},e.autoReinitialiseDelay):!e.autoReinitialise&&X&&clearInterval(X);ma&&c.scrollTop(0)&&K(ma,!1);w&&c.scrollLeft(0)&&V(w,!1);c.trigger("jsp-initialised",[v||x])}function ya(){A.height(G+"px");m=0;Y=e.verticalGutter+A.outerWidth();f.width(j-Y-O);try{0===ea.position().left&&f.css("margin-left",Y+"px")}catch(a){}}function za(){g.find(">.jspHorizontalBar>.jspCap:visible,>.jspHorizontalBar>.jspArrow").each(function(){y-=
b(this).outerWidth()});B.width(y+"px");k=0}function wa(a,d,b,e){var c="before",g="after";"os"==d&&(d=/Mac/.test(navigator.platform)?"after":"split");d==c?g=d:d==g&&(c=d,d=b,b=e,e=d);a[c](b)[g](e)}function F(a,d,c){return function(){var g=this,f=c,g=b(g).addClass("jspActive"),j,h,m=!0,k=function(){0!==a&&l.scrollByX(a*e.arrowButtonSpeed);0!==d&&l.scrollByY(d*e.arrowButtonSpeed);h=setTimeout(k,m?e.initialDelay:e.arrowRepeatFreq);m=!1};k();j=f?"mouseout.jsp":"mouseup.jsp";f=f||b("html");f.bind(j,function(){g.removeClass("jspActive");
h&&clearTimeout(h);h=null;f.unbind(j)});this.blur();return!1}}function va(){B&&B.unbind("mousedown.jsp");A&&A.unbind("mousedown.jsp")}function xa(){b("html").unbind("dragstart.jsp selectstart.jsp mousemove.jsp mouseup.jsp mouseleave.jsp");t&&t.removeClass("jspActive");u&&u.removeClass("jspActive")}function P(a,d){x&&(0>a?a=0:a>C&&(a=C),d===s&&(d=e.animateScroll),d?l.animate(t,"top",a,qa):(t.css("top",a),qa(a)))}function qa(a){a===s&&(a=t.position().top);g.scrollTop(0);m=a;var d=0===m,b=m==C;a=-(a/
C)*(p-h);if(L!=d||M!=b)L=d,M=b,c.trigger("jsp-arrow-change",[L,M,N,Q]);e.showArrows&&(Z[d?"addClass":"removeClass"]("jspDisabled"),$[b?"addClass":"removeClass"]("jspDisabled"));f.css("top",a);c.trigger("jsp-scroll-y",[-a,d,b]).trigger("scroll")}function U(a,d){v&&(0>a?a=0:a>I&&(a=I),d===s&&(d=e.animateScroll),d?l.animate(u,"left",a,pa):(u.css("left",a),pa(a)))}function pa(a){a===s&&(a=u.position().left);g.scrollTop(0);k=a;var d=0===k,b=k==I;a=-(a/I)*(n-j);if(N!=d||Q!=b)N=d,Q=b,c.trigger("jsp-arrow-change",
[L,M,N,Q]);e.showArrows&&(aa[d?"addClass":"removeClass"]("jspDisabled"),ba[b?"addClass":"removeClass"]("jspDisabled"));f.css("left",a);c.trigger("jsp-scroll-x",[-a,d,b]).trigger("scroll")}function K(a,d){P(a/(p-h)*C,d)}function V(a,d){U(a/(n-j)*I,d)}function ga(a,d,c){var f,m,k=0,l=0,p,n,q;try{f=b(a)}catch(t){return}m=f.outerHeight();a=f.outerWidth();g.scrollTop(0);for(g.scrollLeft(0);!f.is(".jspPane");)if(k+=f.position().top,l+=f.position().left,f=f.offsetParent(),/^body|html$/i.test(f[0].nodeName))return;
f=D();p=f+h;k<f||d?n=k-e.verticalGutter:k+m>p&&(n=k-h+m+e.verticalGutter);n&&K(n,c);k=E();n=k+j;l<k||d?q=l-e.horizontalGutter:l+a>n&&(q=l-j+a+e.horizontalGutter);q&&V(q,c)}function E(){return-f.position().left}function D(){return-f.position().top}function T(){return!1}var e,l=this,f,j,h,g,n,p,na,da,x,v,t,C,m,u,I,k,ea,A,Y,G,J,Z,$,fa,B,y,H,aa,ba,X,ta,O,ua,L=!0,N=!0,M=!1,Q=!1,ka=c.clone(!1,!1).empty(),oa=b.fn.mwheelIntent?"mwheelIntent.jsp":"mousewheel.jsp";ta=c.css("paddingTop")+" "+c.css("paddingRight")+
" "+c.css("paddingBottom")+" "+c.css("paddingLeft");O=(parseInt(c.css("paddingLeft"),10)||0)+(parseInt(c.css("paddingRight"),10)||0);b.extend(l,{reinitialise:function(a){a=b.extend({},e,a);la(a)},scrollToElement:function(a,d,b){ga(a,d,b)},scrollTo:function(a,d,b){V(a,b);K(d,b)},scrollToX:function(a,b){V(a,b)},scrollToY:function(a,b){K(a,b)},scrollToPercentX:function(a,b){V(a*(n-j),b)},scrollToPercentY:function(a,b){K(a*(p-h),b)},scrollBy:function(a,b,c){l.scrollByX(a,c);l.scrollByY(b,c)},scrollByX:function(a,
b){var c=(E()+Math[0>a?"floor":"ceil"](a))/(n-j);U(c*I,b)},scrollByY:function(a,b){var c=(D()+Math[0>a?"floor":"ceil"](a))/(p-h);P(c*C,b)},positionDragX:function(a,b){U(a,b)},positionDragY:function(a,b){P(a,b)},animate:function(a,b,c,f){var g={};g[b]=c;a.animate(g,{duration:e.animateDuration,easing:e.animateEase,queue:!1,step:f})},getContentPositionX:function(){return E()},getContentPositionY:function(){return D()},getContentWidth:function(){return n},getContentHeight:function(){return p},getPercentScrolledX:function(){return E()/
(n-j)},getPercentScrolledY:function(){return D()/(p-h)},getIsScrollableH:function(){return v},getIsScrollableV:function(){return x},getContentPane:function(){return f},scrollToBottom:function(a){P(C,a)},hijackInternalLinks:b.noop,destroy:function(){var a=D(),b=E();c.removeClass("jspScrollable").unbind(".jsp");c.replaceWith(ka.append(f.children()));ka.scrollTop(a);ka.scrollLeft(b);X&&clearInterval(X)}});la(q)}q=b.extend({},b.fn.jScrollPane.defaults,q);b.each(["arrowButtonSpeed","trackClickSpeed","keyboardSpeed"],
function(){q[this]=q[this]||q.speed});return this.each(function(){var c=b(this),s=c.data("jsp");s?s.reinitialise(q):(b("script",c).filter('[type="text/javascript"],:not([type])').remove(),s=new Ba(c,q),c.data("jsp",s))})};b.fn.jScrollPane.defaults={showArrows:!1,maintainPosition:!0,stickToBottom:!1,stickToRight:!1,clickOnTrack:!0,autoReinitialise:!1,autoReinitialiseDelay:500,verticalDragMinHeight:0,verticalDragMaxHeight:99999,horizontalDragMinWidth:0,horizontalDragMaxWidth:99999,contentWidth:s,animateScroll:!1,
animateDuration:300,animateEase:"linear",hijackInternalLinks:!1,verticalGutter:4,horizontalGutter:4,mouseWheelSpeed:10,arrowButtonSpeed:0,arrowRepeatFreq:50,arrowScrollOnHover:!1,trackClickSpeed:0,trackClickRepeatFreq:70,verticalArrowPositions:"split",horizontalArrowPositions:"split",enableKeyboardNavigation:!0,hideFocus:!1,keyboardSpeed:0,initialDelay:300,speed:30,scrollPagePercent:0.8}})(jQuery,this);
