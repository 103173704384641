<template>
  <div id="popup-fav" class="popup popup__texture popup__unfav mfp-hide">
    <client-only>
      <div class="popup_unfav">
        <div class="decor decor__1-alter" />
        <div class="popup_title">
          Раздел<span class="icon icon__inline icon__heart-medium" /><span>любимое</span><br>пока пуст
        </div>
        <p>
          Вы можете добавлять понравившиеся вам товары в&nbsp;Любимое, чтобы в дальнейшем удобно просматривать их все в
          одном месте.
        </p>
        <p>
          Для добавления в Любимое вам нужно нажать на значек<span class="icon icon__inline icon__heart-small" />который находится рядом с каждым платьем или&nbsp;аксессуаром.
        </p>
      </div>
    </client-only>
  </div>
</template>

<script>
export default {
  name: 'FavoritePopup'
}
</script>

<style></style>
