import { cookieStore } from '@/assets/js/storejs'

export const state = () => ({
  items: [],
  version: 1
})

export const mutations = {
  toggle (state, value) {
    const favState = loadFavoritesFromStorage()
    // eslint-disable-next-line no-prototype-builtins
    if (favState.hasOwnProperty('version') && favState.version !== state.version) {
      state.items = []
    }

    // eslint-disable-next-line no-prototype-builtins
    if (favState.hasOwnProperty('items') && favState.items.length) {
      state.items = favState.items
    }

    if (!state.items.includes(value)) {
      state.items.push(value)
    } else {
      state.items = state.items.filter(itemId => itemId !== value)
    }
    saveFavoritesToStorage(state)
  },
  setItems (state, value) {
    state.items = value
    saveFavoritesToStorage(state)
  }
}

export const actions = {
  toggle (context, value) {
    context.commit('toggle', value)
    return context.state.items.includes(value)
  },
  loadFromStore (context) {
    loadFromStoreFunc()

    setInterval(loadFromStoreFunc, 900)

    function loadFromStoreFunc () {
      const favState = loadFavoritesFromStorage()

      // eslint-disable-next-line no-prototype-builtins
      if (favState.hasOwnProperty('version') && favState.version !== context.state.version) {
        context.commit('setItems', [])
        saveFavoritesToStorage(context.state)
      }

      // eslint-disable-next-line no-prototype-builtins
      if (favState.hasOwnProperty('items') && favState.items.length) {
        context.commit('setItems', favState.items)
      }
    }
  }
}

function saveFavoritesToStorage (state) {
  cookieStore.set('favorites', JSON.stringify(state))
}
function loadFavoritesFromStorage () {
  let favState = {}

  try {
    if (cookieStore.get('favorites')) {
      favState = JSON.parse(cookieStore.get('favorites'))
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error loading from storejs', error)
  }

  return favState
}
