import throttle from 'lodash.throttle'
import Vue from 'vue'
import Bus from '../../assets/js/bus'

/* eslint-disable */
/**
 * Init
 */
export const initApp = function(pageClasses, store) {
  app.store = store;
  app.placeholder()
  app.inputGroup()
  app.nav()
  app.search()
  app.accordion()
  app.popups()
  app.initMobile()

  if ($('body').hasClass('page-front') || (pageClasses && pageClasses.indexOf('page-front') !== -1)) {
    app.front()
    app.factsSwiper()
  }

  if ($('body').hasClass('page-vacuum') || (pageClasses && pageClasses.indexOf('page-vacuum') !== -1)) {
    app.vacuum()
  }
}

/**
 * Setup
 */
export const app = Vue.observable({
  store: null,
  prepared: false,
  touch: false,
  mac: false,
  mobile: false,
  tablet: false,
  isIpadDesktop: false,
  screenOrientation: null,

  /**
   * Base
   */
  base() {
    // mobile or desktop
    if (
      /mobile|tablet|phone|ip(ad|od)|blackberry|android|silk|webos/i.test(
        navigator.userAgent
      ) ||
      /opera mini/i.test(navigator.userAgent)
    ) {
      document.documentElement.className += ' app-mobile'
    } else {
      document.documentElement.className += ' app-desktop'
    }

    app.screenOrientation = window.screen.orientation


    if (app.screenOrientation && app.screenOrientation.lock) {
      app.screenOrientation.lock("portrait").catch(error => {
        console.log('Error', error)
      })
    }


    // touch
    app.touch = 'ontouchend' in window
    app.tablet = /ipad|tablet/i.test(navigator.userAgent)
    app.mobile = (function() {
      if (
        /iphone|ipod|android|blackberry|windows phone|opera mini|silk/i.test(
          navigator.userAgent
        )
      ) {
        $('html').addClass('mobile')
        return true
      } else {
        return false
      }
    })()
    app.scroll = 'scroll' + (app.touch ? ' touchstart touchmove touchend' : '')
    app.orientation = !!window.DeviceOrientationEvent

    // browsers
    app.mac = (function() {
      if (/mac|ipad|iphone|ipod/i.test(navigator.userAgent)) {
        $('html').addClass('osx')
        return true
      } else {
        return false
      }
    })()

    app.isIpadDesktop = app.touch && app.mac && !app.mobile

    app.chrome = !!window.chrome
    app.safari = (function() {
      if (/safari/i.test(navigator.userAgent) && !app.chrome) {
        $('html').addClass('safari')
        return true
      } else {
        return false
      }
    })()
    app.ie = !!$('html').hasClass('ie')
    app.ie7 = !!$('html').hasClass('ie7')
    // css3 detection
    app.transition
    app.transitionPrefix = ''
    app.transitions = (function() {
      const props = [
        'transition',
        'msTransition',
        'MozTransition',
        'OTransition',
        'WebkitTransition'
      ]
      let result = false

      for (const prop in props) {
        if (typeof document.documentElement.style[props[prop]] === 'string') {
          app.transition = props[prop]
          result = true

          if (app.transition !== props[0]) {
            app.transitionPrefix =
              '-' + app.transition.replace('Transition', '').toLowerCase() + '-'
          }
        }
      }

      return result
    })()
    app.perspective
    app.transform
    app.transforms = (function() {
      const props = [
        'perspective',
        'msPerspective',
        'MozPerspective',
        'OPerspective',
        'WebkitPerspective'
      ]
      let result = false

      for (const prop in props) {
        if (typeof document.documentElement.style[props[prop]] === 'string') {
          app.perspective = props[prop]
          result = true

          if (app.perspective == props[0]) {
            app.transform = 'transform'
          } else {
            app.transform =
              app.perspective.replace('Perspective', '') + 'Transform'
          }
        }
      }

      return result
    })()

    // popup delay
    app.delay = app.transitions ? 400 : 0

    // window
    app.win = $(window)
    app.offset = null
    app.offsetUpdate = function() {
      app.offset =
        window.scrollY || window.pageYOffset || app.win.scrollTop() || 0
    }
    app.offsetUpdate()
    app.sizeUpdate = function() {
      app.width = app.win.width()
      app.height = app.win.height()
    }
    app.sizeUpdate()

    // window metrics
    app.win.on(app.scroll, app.offsetUpdate)
    app.win.on('resize', throttle(app.sizeUpdate, 250))

    app.propertizer = function(string) {
      if (~string.indexOf('-')) {
        return string.replace(/-([\da-z])/gi, function(all, letter) {
          return letter.toUpperCase()
        })
      } else {
        return string
      }
    }
  },

  /**
   * Placeholder
   */
  placeholder(selector) {
    if (selector && selector.length) {
      selector.placeholder()
    } else {
      $('input[placeholder], textarea[placeholder]').placeholder()
    }
  },

  factsSwiper() {
      if (window.mobile) {
        // Index facts slider
        $('.teaser__facts').swipe({
          threshold: 25,
          swipeLeft() {
            const nextIndex =
              $('.teaser_facts_pager .is-active').index() ===
              $('.teaser_facts_pager li').length - 1
                ? 0
                : $('.teaser_facts_pager .is-active').index() + 1
            $('.teaser_facts_pager li')
              .eq(nextIndex)
              .trigger('click')
          },
          swipeRight() {
            const nextIndex =
              $('.teaser_facts_pager .is-active').index() === 0
                ? $('.teaser_facts_pager li').length - 1
                : $('.teaser_facts_pager .is-active').index() - 1
            $('.teaser_facts_pager li')
              .eq(nextIndex)
              .trigger('click')
          }
        })
      }
  },

  initMobile() {
    if (window.mobile) {
      if (md.os() == 'iOS') {
        let tempScrollTop
        let currentScrollTop = 0
        let marker = true

        function popupOn() {
          setTimeout(function() {
            $('.popup-overlay').addClass('show')
            $('.tooltip').addClass('show')
          }, 1200)

          marker = false
        }
      }

      $('body').click(function(event) {
        const $target = $(event.target)

        if (
          $('.tooltip.show').length &&
          !$target.closest('.social-share').length
        ) {
          $('.tooltip').removeClass('show')
        }
      })

      $('.popup-overlay').click(function(event) {
        if ($('.tooltip.show').length) {
          $('.tooltip').removeClass('show')
          $(this).removeClass('show')
        }
      })


      $(document).on('scroll', function() {
        if ($(window).scrollTop() < 1) {
          $('.header').removeClass('fixed')
        } else {
          $('.header').addClass('fixed')
        }
      })
    }
  },

  /**
   * Group fields
   */
  inputGroup(selector) {
    if (selector && selector.length) {
      $(selector).groupinputs()
    } else {
      $('.group-field').groupinputs()
    }
  },

  /**
   * Accordion
   */
  accordion(selector) {
    $(selector && selector.length ? selector : '.js-accordion').each(
      function() {
        const dl = $(this)

        dl.find('dt').on('click', function(event) {
          event.preventDefault()

          const dt = $(this)
          const dd = dt.next('dd')

          if (
            !dt.hasClass('is-active') &&
            !dt.hasClass('is-animated') &&
            dd.length
          ) {
            dt.addClass('is-animated')

            // slide up
            if (dt.hasClass('is-expanded')) {
              dt.removeClass('is-expanded')
              dd.slideUp(300, function() {
                dt.removeClass('is-animated')
              })

              // slide down
            } else {
              dt.addClass('is-expanded')
              dd.slideDown(300, function() {
                dt.removeClass('is-animated')
              })
            }
          }
        })
      }
    )
  },

  /**
   * Nav
   */
  nav() {
    if (app.mobile) {
      const items = $('.nav_item')

      $('.nav_item.is-dropdown .nav_item_link').on('click', function(event) {
        const parent = $(this).parent()

        if (!parent.hasClass('is-hover')) {
          event.preventDefault()

          items.removeClass('is-hover')
          parent.addClass('is-hover')
        }
      })

      app.win.on('click', function(event) {
        const target = $(event.target)

        if (!target.hasClass('nav_item_link')) {
          items.removeClass('is-hover')
        }
      })
    }
  },

  /**
   * Search
   */
  search() {
    if (!app.transitions) {
      // search field
      $('.header .nav_search input').on('focus blur', function(event) {
        const input = $(this)

        if (event.type == 'focus') {
          input.stop(true, true).animate({ width: 230 }, 300)
        } else {
          input.stop(true, true).animate({ width: 170 }, 300)
        }
      })
    }

    $('form.nav_search, form.wrapper_search').each(function() {
      const form = $(this)
      let valid = false
      const input = form.find('input')
      const placeholder = input.attr('placeholder')
      let value

      input
        .on('focus blur change keyup', function() {
          value = $(this).val()

          if (value.length < 2 || value == placeholder) {
            valid = false
            form.removeClass('is-active')
          } else {
            valid = true
            form.addClass('is-active')
          }
        })
        .blur()

      form.on('submit', function(event) {
        if (!valid) {
          event.preventDefault()

          input.focus()
        }
      })
    })
  },

  fixPopupFocus() {
    $.magnificPopup.instance._onFocusIn = function(e) {
      return true
    }
  },

  /**
   * Popup
   */
  popup(popupClass, popupContent, delay, delayClass, redirect, showCloseBtn, id) {
    $.magnificPopup.instance._onFocusIn = function(e) {
      // Do nothing if target element is select2 input
      if ($(e.target).closest('.fitting-widget')) {
        return true
      }
      // Else call parent method
      $.magnificPopup.proto._onFocusIn.call(this, e)
    }
    if (popupClass || popupContent || id) {
      // product
      if ($.isArray(popupClass)) {
        $.magnificPopup.open({
          items: {
            src:
              '<div class="' +
              popupClass[0] +
              '" id="' +
              popupClass[1] +
              '">' +
              popupContent +
              '</div>',
            type: 'inline'
          },
          closeMarkup:
            '<div class="mfp-close mfp-close__in" title="%title%"></div>',
          closeBtnInside: true,
          removalDelay: app.delay,
          mainClass: 'mfp-fade mfp-product',
          callbacks: {
            close() {
              $('body').removeClass('dialog-opened')
              if (this.items[0] && this.items[0].src) {
                if ($(this.items[0].src).hasClass('popup__product')) {
                  $('.zoomContainer').remove()
                }
              }
              return true
            },
            open() {
              $('body').addClass('dialog-opened')
              app.fixPopupFocus();
            }
          }
        })
      } else {
        const obj = {
          removalDelay: app.delay,
          mainClass: 'mfp-alert',
          callbacks: {
            close() {
              $('body').removeClass('dialog-opened')
            },
            open() {
              $('body').addClass('dialog-opened')
              app.fixPopupFocus();
            }
          },
        }

        if (popupContent && popupClass) {
          obj.items = {
            src: '<div class="' + popupClass + '">' + popupContent + '</div>',
            type: 'inline'
          }
        } else if (id) {
          obj.items = {
            src: id,
            type: 'inline'
          }
        }

        if (showCloseBtn) {
          obj.closeMarkup = '<div class="mfp-close mfp-close__in" title="%title%"></div>';
          obj.closeBtnInside = true;
        } else {
          obj.showCloseBtn = false;
          obj.closeOnContentClick = true;
        }

        $.magnificPopup.open(obj)

        if (delayClass) {
          if (!delay) {
            delay = 1000
          }

          setTimeout(function() {
            if (redirect) {
              window.location.href = homeLink
            } else {
              const popup = $.magnificPopup.instance

              if (popup.content) {
                if (popup.content.hasClass(delayClass)) {
                  $.magnificPopup.close()
                }
              }
            }
          }, delay)
        }
      }
    }
  },

  /**
   * Popups
   */
  popups() {
    $('.js-popup').each(function() {
      const item = $(this)
      const decor = item.data('mfp-decor')

      item.magnificPopup({
        type: 'inline',
        midClick: true,
        closeMarkup:
          '<div class="mfp-close mfp-close__in" title="%title%"></div>',
        closeBtnInside: true,
        callbacks: {
          close() {
            $('body').removeClass('dialog-opened')
            Bus.$emit('jsPopupClosed')
          },
          open() {
            $('body').addClass('dialog-opened')
            app.fixPopupFocus();
          }
        },
        removalDelay: app.delay,
        mainClass() {
          let classname = 'mfp-fade'

          if (decor && decor.length) {
            classname = classname + ' ' + decor
          }

          return classname
        }
      })
    })

    // translation
    $.extend(true, $.magnificPopup.defaults, {
      tClose: 'Закрыть (Esc)',
      tLoading: 'Загрузка...',
      gallery: {
        tPrev: 'Назад (&larr;)',
        tNext: 'Вперед (&rarr;)',
        tCounter: '%curr% из %total%',
        arrowMarkup:
          '<div class="mfp-arrow mfp-arrow-%dir%" title="%title%"><span></span></div>'
      },
      image: {
        tError: 'Ошибка при загрузке <a href="%url%">фото</a>.'
      },
      inline: {
        tNotFound: 'Ошибка при загрузке контента.'
      },
      ajax: {
        tError: 'Ошибка при загрузке <a href="%url%">контента</a>.'
      }
    })

    $(document).magnificPopup({
      delegate: '.js-popup',
      type: 'inline',
      midClick: true,
      closeMarkup:
        '<div class="mfp-close mfp-close__in" title="%title%"></div>',
      closeBtnInside: true,
      removalDelay: app.delay,
      mainClass: 'mfp-fade mfp-product',
      callbacks: {
        close(){
          Bus.$emit('jsPopupClosed')
        }
      },
    })
  },

  /**
   * Front page
   */
  front() {
    const teasers = $('.teaser')

    // teaser slides
    teasers.each(function() {
      const teaser = $(this)
      const loader = teaser.find('.teaser_loader')
      const hint = teaser.find('.teaser_hint')
      const hintRemove = function() {
        if (hint.hasClass('is-active')) {
          hint.removeClass('is-active')
        }
      }
      const slides = teaser.find('.swiper-slide')
      let count = 0

      // remove hint
      hint.hoverIntent({
        over: hintRemove,
        timeout: 200,
        out() {
          // do nothing
        }
      })

      $('.catalog_fav').on('click', hintRemove)

      // remove loader

      //app.win.load(function() {})

      // goodies slider
      if (slides.length && loader.length) {
        loader.on('click', function() {
          if (!loader.hasClass('is-animated') && slides.length > 1) {
            if (app.transforms) {
              loader.addClass('is-animated')
              loader[0].style[app.transform] =
                'rotate(360deg)'

              setTimeout(function() {
                loader.removeClass('is-animated')
                loader[0].style[app.transform] =
                  null
              }, 650)
            }

            const current = slides.filter('.is-active')

            if (current.length) {
              const currentIndex = current.index()
              let next = slides.eq(currentIndex + 1)

              if (!next.length) {
                next = slides.eq(0)
              }

              current.removeClass('is-active')
              next.addClass('is-active')
            }
          }
        })
      }

      // facts slider
      const factsHolder = teaser.find('.teaser_facts_slides')
      const facts = factsHolder.find('li')
      const captions = teaser.find('.teaser_facts_captions li')
      const pager = teaser.find('.teaser_facts_pager li')
      const num = teaser.find('.teaser_facts_title span')

      let interval
      let indexCurrent = 0

      const update = function(user, index) {
        if (!num.hasClass('is-animated')) {
          stop()

          if (user !== true && index !== 0) {
            if (pager.eq(indexCurrent + 1).length) {
              index = indexCurrent + 1
            } else {
              index = 0
            }
          }

          // index of the next slide
          indexCurrent = index

          // update num, prevent flickering
          if (app.transitions) {
            num.addClass('is-animated').text(index + 1)
            setTimeout(function() {
              num.removeClass('is-animated')
              play()
            }, 1000)
          } else {
            num.text(index + 1)
            play()
          }

          // pager 'is-active'
          const previous = pager.filter('.is-active')

          if (previous.length) {
            const previousIndex = previous.index()

            previous.removeClass('is-active')
            pager.eq(previousIndex).removeClass('is-active')
            teaser
              .removeClass('fact-' + (previousIndex + 1))
              .addClass('fact-' + (index + 1))
          }

          pager.eq(index).addClass('is-active')

          if (captions.length == facts.length) {
            captions
              .removeClass('is-active')
              .eq(index)
              .addClass('is-active')
          }

          facts
            .removeClass('is-active')
            .eq(index)
            .addClass('is-active')
        }
      }
      const play = function(additional) {
        if (!additional) {
          additional = 0
        }

        interval = setInterval(
          update,
          additional + (app.transitions ? 4000 : 5000)
        )
      }
      const stop = function() {
        interval = clearInterval(interval)
      }

      if (facts.length && facts.length > 1) {
        if (facts.length == pager.length && num.length) {
          // autoplay
          play(app.transitions ? 3500 : 2500)

          if ($.event.special.swipe) {
            factsHolder.on('swipeleft swiperight dragstart', function(event) {
              let index

              if (event.type == 'dragstart' && event.cancelable) {
                event.preventDefault()
              } else if (event.type == 'swipeleft') {
                if (pager.eq(indexCurrent + 1).length) {
                  index = indexCurrent + 1
                } else {
                  index = 0
                }

                update(true, index)
              } else {
                if (pager.eq(indexCurrent - 1).length) {
                  index = indexCurrent - 1
                } else {
                  index = pager.length - 1
                }

                update(true, index)
              }
            })
          }

          pager.on('click', function(event) {
            event.preventDefault()

            const item = $(this)
            const index = item.index()

            if (!item.hasClass('is-active')) {
              update(true, index)
            }
          })
        }
      }
    })

    // cloud effect
    const cloudItems = [
      {
        selector: $('.teaser_poster_inner a'),
        top: 2,
        left: 2
      },
      {
        selector: $('.decor__2'),
        top: 6,
        left: 6
      },
      {
        selector: $('.decor__5'),
        top: 6,
        left: 6
      },
      {
        selector: $('.decor__7'),
        top: 4,
        left: 4
      },
      {
        selector: $('.decor__8'),
        top: 4,
        left: 4
      },
      {
        selector: $('.decor__9'),
        top: 2,
        left: 2
      },
      {
        selector: $('.decor__10'),
        top: 6,
        left: 6
      },
      {
        selector: $('.decor__12'),
        top: 2,
        left: 2
      },
      {
        selector: $('.decor__13'),
        top: 2,
        left: 2
      },
      {
        selector: $('.decor__14'),
        top: 4,
        left: 4
      },
      {
        selector: $('.decor__15'),
        top: 4,
        left: 4
      },
      {
        selector: $('.decor__16'),
        top: 6,
        left: 6
      }
    ]

    app.cloud(cloudItems, 0, 1000, -50)
  },

  /**
   * Vacuum
   */
  vacuum() {
    // cloud effect
    const cloudItems = [
      {
        selector: $('.decor__2'),
        top: 6,
        left: 6
      },
      {
        selector: $('.decor__5'),
        top: 6,
        left: 6
      },
      {
        selector: $('.decor__7'),
        top: 4,
        left: 4
      },
      {
        selector: $('.decor__8'),
        top: 4,
        left: 4
      },
      {
        selector: $('.decor__9'),
        top: 6,
        left: 6
      },
      {
        selector: $('.decor__11'),
        top: 2,
        left: 2
      }
    ]

    app.cloud(cloudItems, false, false, false)
  },

  /**
   * Cloud
   */
  cloud(items, min, max, topOffset) {
    if (!app.transforms || app.mobile) {
      // && !app.orientation
      return
    }

    let x
    let y
    let time

    if (items.length) {
      for (var item in items) {
        if (
          typeof items[item].selector === 'object' &&
          items[item].selector.length
        ) {
          items[item].style = items[item].selector[0].style
        }
      }

      if (topOffset && app.mobile && app.orientation) {
        for (var item in items) {
          if (items[item].style) {
            items[item].style.marginTop = topOffset + 'px'
          }
        }
      }

      let styles
      const animate = function() {
        if (styles.length) {
          if (time) {
            time = Date.now()
          }

          for (const item in styles) {
            items[styles[item][0]].style[styles[item][1]] = styles[item][2]
          }

          styles = []
        }
      }

      const parse = function() {
        // console.log(x);
        // console.log(y);
        styles = []

        for (var item in items) {
          ;(function() {
            if (items[item].style) {
              // set position
              const top = -1 * (y - app.height / 2) * (items[item].top / 100)
              const left = -1 * (x - app.width / 2) * (items[item].left / 100)
              let speed

              items[item].position = [left, top]

              // check position
              if (items[item].position !== items[item].current) {
                items[item].current = items[item].position
              } else {
                return
              }

              if (!time) {
                time = Date.now()
              }

              if (Date.now() - time > 50) {
                speed = 400
              } else {
                speed = 0
              }

              // style
              if (
                !(
                  /Version\/8/.test(navigator.userAgent) &&
                  /safari/i.test(navigator.userAgent) &&
                  !app.chrome
                )
              ) {
                styles.push([
                  item,
                  app.transition,
                  app.transitionPrefix + 'transform ' + speed + 'ms ease-out'
                ])
              }
              styles.push([
                item,
                app.transform,
                'translate3d(' +
                  items[item].position[0] +
                  'px, ' +
                  items[item].position[1] +
                  'px, 0)'
              ])
            }
          })()
        }

        animate()
      }

      parse()

      if (app.mobile && app.orientation) {
        let orientationData
        let orientationBeta
        let orientationGamma
        const orientationMultiplier = 30
        const orientationDelay = setInterval(function() {
          if (orientationData) {
            if (
              (app.offset >= min && app.offset <= max) ||
              (max == false && max == false)
            ) {
              orientationBeta = orientationData.beta || 0
              orientationGamma = orientationData.gamma || 0

              if (app.width > app.height) {
                x = orientationBeta * orientationMultiplier
                y = orientationGamma * orientationMultiplier
              } else {
                x = orientationGamma * orientationMultiplier
                y = orientationBeta * orientationMultiplier
              }

              parse()
            }
          }
        }, 100)

        window.addEventListener(
          'deviceorientation',
          function(event) {
            orientationData = event
          },
          false
        )
      } else {
        app.win.on('mouseenter mousemove mouseleave resize', function(event) {
          if (
            (app.offset >= min && app.offset <= max) ||
            (max == false && max == false)
          ) {
            x = event.pageX
            y = event.pageY

            parse()
          }
        })
      }

      app.win.on(
        'scroll',
        throttle(function(event) {
          if (
            (app.offset >= min && app.offset <= max) ||
            (max == false && max == false)
          ) {
            x = event.pageX
            y = event.pageY

            parse()
          }
        }, 400)
      )
    }
  },
})
