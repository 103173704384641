<template>
  <div
    ref="container"
    class="container"
    :class="{
      'with-placeholder': usePlaceholder
    }"
    :style="usePlaceholder ? { paddingTop: '150%' } : null"
  >
    <img
      ref="img"
      v-lazy="{
        src: src,
        loading: '/img/preloader.svg',
        throttleWait: 5
      }"
      src="/img/preloader.svg"
      class="zoom-image"
      :data-zoom="zoomSrc"
      :alt="alt"
      itemprop="image"
    >
  </div>
</template>
<script>
import Drift from 'drift-zoom'
import 'drift-zoom/dist/drift-basic.min.css'
import { app } from 'assets/js/common'

export default {
  name: 'ZoomableImage',
  props: {
    src: {
      type: String,
      default: ''
    },
    zoomSrc: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    driftOptions: {
      type: Object,
      default: () => ({})
    },
    imageRatio: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    usePlaceholder () {
      return typeof this.imageRatio !== 'undefined'
    },
    isTabletOrMobile () {
      return app.isIpadDesktop || app.tablet || app.mobile
    }
  },
  mounted () {
    if (!this.$isServer && this.$refs.img && !this.isTabletOrMobile) {
      this.drift = new Drift(this.$refs.img, {
        paneContainer: this.$refs.container,
        inlinePane: true,
        handleTouch: false,
        ...this.driftOptions
      })
    }
  },
  destroyed () {
    if (this.drift) {
      this.drift.destroy()
    }
  }
}
</script>
<style lang="scss">
.container {
  position: relative;
}

.container.with-placeholder {
  height: 0;
  background-color: #eee;

  .zoom-image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
}

.drift-zoom-pane.drift-inline {
  z-index: 10000;
  width: 350px;
  height: 350px;
  border-radius: 50%;
}

.zoom-image {
  position: relative;
}

.container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  @media only screen and (min-width: 1100px) {
    display: none;
  }
}
</style>
