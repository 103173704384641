<template>
  <header class="header">
    <div class="ancillary">
      <nuxt-link to="/">
        <div class="ancillary_logo">
          <span>Love Forever</span>
        </div>
      </nuxt-link>
      <div class="ancillary_items">
        <ul class="ancillary_item ancillary_links">
          <li class="ancillary_item ancillary_link">
            <nuxt-link to="/reviews/">
              Невесты и отзывы
            </nuxt-link>
          </li>
          <div class="ancillary_item ancillary_link">
            <nuxt-link to="/articles/">
              Статьи
            </nuxt-link>
          </div>
          <li class="ancillary_item ancillary_link ancillary_item_address">
            <i class="icon icon__pin" />
            <nuxt-link to="/contacts/#contacts">
              Адрес салона
            </nuxt-link>
          </li>
        </ul>
        <div class="ancillary_item ancillary_fav is-active">
          <nuxt-link v-if="favoriteItems.length" :to="favoriteLink">
            <i class="icon icon__inline icon__fav-active" /><span>Любимое ({{ favoriteItems.length }})</span><span class="count">{{ favoriteItems.length }}</span>
          </nuxt-link>
          <a
            v-else
            class="js-popup"
            data-mfp-decor="mfp-product"
            href="#popup-fav"
          ><i class="icon icon__inline icon__fav-active" /><span>Любимое (0)</span><span class="count">0</span></a>
        </div>

        <address class="ancillary_item ancillary_phone ancillary_header">
          <a href="tel:+74956451908" @click="phoneNumberHandler">8 (495) 645 19 08</a>
          <br>
          <!--<span>8 916 820 44 97</span>
          (ателье)-->
        </address>
        <div class="callback-link">
          <a
            class="pseudo js-popup js-request"
            data-mfp-decor="mfp-product"
            href="#popup-request"
          >Ежедневно с 10:00 до 22:00</a>
        </div>
      </div>
    </div>
    <div class="header__first-line">
      <nuxt-link to="/">
        <div class="site-logo">
          <img src="/img/logos/logo.jpg" alt="">
        </div>
      </nuxt-link>
      <nuxt-link class="header-link" to="/contacts/#contacts">
        <i class="icon icon__pin" />
        <span>Адрес салона</span>
      </nuxt-link>

      <div>
        <a class="phone-link" href="tel:+74956451908" @click="phoneNumberHandler">8 (495) 645 19 08</a>
        <a
          class="work-time-link pseudo js-popup js-request"
          data-mfp-decor="mfp-product"
          href="#popup-request"
        >Ежедневно с 10:00 до 22:00</a>
      </div>

      <nuxt-link v-if="favoriteItems.length" class="header-link favourite-link" :to="favoriteLink">
        <i class="icon icon__inline icon__fav-active" />
        <span>Любимое ({{ favoriteItems.length }})</span>
        <span class="count">{{ favoriteItems.length }}</span>
      </nuxt-link>
      <a v-else class="header-link favourite-link js-popup" data-mfp-decor="mfp-product" href="#popup-fav">
        <i class="icon icon__inline icon__fav-active" />
        <span>Любимое (0)</span>
        <span class="count">0</span></a>

      <div class="nav_search">
        <input v-model="searchQuery" placeholder="поиск" type="text" @keypress.enter="searchHandler">
        <button v-if="searchQuery.length > 1" @click="searchHandler">
          Искать
        </button>
      </div>
    </div>
    <nav class="nav">
      <ul class="nav_items">
        <li
          v-if="weddingCategory && weddingCategory.active"
          :class="{ 'is-hover': hoveredNavigationItem === 'Свадебные платья' }"
          class="nav_item nav_item__wedding is-dropdown"
          @mouseover="hoveredNavigationItem = 'Свадебные платья'"
          @mouseleave="hoveredNavigationItem = null"
        >
          <nuxt-link
            class="nav_item_link"
            to="/wedding/"
            @click.native="menuItemClickHandler('/wedding/')"
          >
            Свадебные платья
          </nuxt-link>
          <div class="nav_dropdown nav_dropdown__alter">
            <div class="nav_dropdown_cols">
              <div class="nav_dropdown_col style">
                <strong class="nav_dropdown_title">Стили</strong>
                <ul class="nav_dropdown_list nav_dropdown_list__slider">
                  <li v-for="style in weddingStyles" :key="style.id">
                    <nuxt-link
                      :to="`/wedding/?style=${style.slug}`"
                      @click.native="menuItemClickHandler(`/wedding/?style=${style.slug}`)"
                    >
                      <strong>{{ style.name }}</strong>
                      <ins
                        :class="[
                          `nav_style__${style.slug === 'korotkie' ? 'korotkie1' : style.slug}`,
                          { nav_style__hidden: !style.with_pic }
                        ]"
                        class="nav_style"
                      />
                    </nuxt-link>
                  </li>
                </ul>
              </div>
              <div class="nav_dropdown_col brand">
                <strong class="nav_dropdown_title">Топ бренды</strong>
                <ul class="nav_dropdown_list">
                  <div class="left">
                    <li v-for="brand in weddingBrandsLeft" :key="brand.id">
                      <nuxt-link
                        :to="`/wedding/${brand.slug}/`"
                        @click.native="menuItemClickHandler(`/wedding/${brand.slug}/`)"
                      >
                        {{ brand.name_eng }}
                      </nuxt-link>
                    </li>
                  </div>
                  <div class="right">
                    <li v-for="brand in weddingBrandsRight" :key="brand.id">
                      <nuxt-link
                        :to="`/wedding/${brand.slug}/`"
                        @click.native="menuItemClickHandler(`/wedding/${brand.slug}/`)"
                      >
                        {{ brand.name_eng }}
                      </nuxt-link>
                    </li>
                  </div>
                  <div class="clear" />
                </ul>
              </div>
              <div class="nav_dropdown_col">
                <strong class="nav_dropdown_title">Стоимость</strong>
                <ul class="nav_dropdown_list">
                  <li>
                    <nuxt-link
                      to="/wedding/?price_min=10000&price_max=25000"
                      @click.native="menuItemClickHandler('/wedding/?price_min=10000&price_max=25000')"
                    >
                      10000 - 25000<span class="ruble">р</span>
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link
                      to="/wedding/?price_min=25000&price_max=50000"
                      @click.native="menuItemClickHandler('/wedding/?price_min=25000&price_max=50000')"
                    >
                      25000 - 50000<span class="ruble">р</span>
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link
                      to="/wedding/?price_min=50000&price_max=75000"
                      @click.native="menuItemClickHandler('/wedding/?price_min=50000&price_max=75000')"
                    >
                      50000 - 75000<span class="ruble">р</span>
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link
                      to="/wedding/?price_min=75000&price_max=1000000"
                      @click.native="menuItemClickHandler('/wedding/?price_min=75000&price_max=1000000')"
                    >
                      от 75000<span class="ruble">р</span>
                    </nuxt-link>
                  </li>
                </ul>
              </div>
              <div class="nav_dropdown_col nav_dropdown_col__proceed">
                <nuxt-link
                  to="/wedding/"
                  @click.native="menuItemClickHandler('/wedding/')"
                >
                  <i /><span>Смотреть<br>полный каталог</span>
                </nuxt-link>
              </div>
            </div>
          </div>
        </li>
        <li
          v-if="eveningCategory && eveningCategory.active"
          :class="{ 'is-hover': hoveredNavigationItem === 'Вечерние' }"
          class="nav_item nav_item__evening is-dropdown"
          @mouseover="hoveredNavigationItem = 'Вечерние'"
          @mouseleave="hoveredNavigationItem = null"
        >
          <nuxt-link
            class="nav_item_link"
            to="/evening/"
            @click.native="menuItemClickHandler('/evening/')"
          >
            Вечерние
          </nuxt-link>
          <div class="nav_dropdown nav_dropdown__alter">
            <div class="nav_dropdown_cols">
              <div class="nav_dropdown_col style">
                <strong class="nav_dropdown_title">Стили</strong>
                <ul class="nav_dropdown_list nav_dropdown_list__slider">
                  <li v-for="style in eveningStyles" :key="style.id">
                    <nuxt-link
                      :to="`/evening/?style=${style.slug}`"
                      @click.native="menuItemClickHandler(`/evening/?style=${style.slug}`)"
                    >
                      <strong>{{ style.name }}</strong>
                      <ins
                        v-show="style.with_pic"
                        :class="[`nav_style__${style.slug}`, { nav_style__hidden: !style.with_pic }]"
                        class="nav_style"
                      />
                    </nuxt-link>
                  </li>
                </ul>
              </div>
              <div class="nav_dropdown_col brand">
                <strong class="nav_dropdown_title">Топ бренды</strong>
                <ul class="nav_dropdown_list">
                  <div class="left">
                    <li v-for="brand in eveningBrandsLeft" :key="brand.id">
                      <nuxt-link
                        :to="`/evening/${brand.slug}/`"
                        @click.native="menuItemClickHandler(`/evening/${brand.slug}/`)"
                      >
                        {{ brand.name_eng }}
                      </nuxt-link>
                    </li>
                  </div>
                  <div class="right">
                    <li v-for="brand in eveningBrandsRight" :key="brand.id">
                      <nuxt-link
                        :to="`/evening/${brand.slug}/`"
                        @click.native="menuItemClickHandler(`/evening/${brand.slug}/`)"
                      >
                        {{ brand.name_eng }}
                      </nuxt-link>
                    </li>
                  </div>
                  <div class="clear" />
                </ul>
              </div>
              <div class="nav_dropdown_col">
                <strong class="nav_dropdown_title">Стоимость</strong>
                <ul class="nav_dropdown_list">
                  <li>
                    <nuxt-link
                      to="/evening/?price_min=10000&price_max=25000"
                      @click.native="menuItemClickHandler('/evening/?price_min=10000&price_max=25000')"
                    >
                      10000 - 25000<span class="ruble">р</span>
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link
                      to="/evening/?price_min=25000&price_max=50000"
                      @click.native="menuItemClickHandler('/evening/?price_min=25000&price_max=50000')"
                    >
                      25000 - 50000<span class="ruble">р</span>
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link
                      to="/evening/?price_min=50000&price_max=1000000"
                      @click.native="menuItemClickHandler('/evening/?price_min=50000&price_max=1000000')"
                    >
                      от 50000<span class="ruble">р</span>
                    </nuxt-link>
                  </li>
                </ul>
              </div>
              <div class="nav_dropdown_col nav_dropdown_col__proceed">
                <nuxt-link
                  to="/evening/"
                  @click.native="menuItemClickHandler('/evening/')"
                >
                  <i /><span>Смотреть<br>полный каталог</span>
                </nuxt-link>
              </div>
            </div>
          </div>
        </li>
        <li
          v-if="promCategory && promCategory.active"
          :class="{ 'is-hover': hoveredNavigationItem === 'Выпускные' }"
          class="nav_item nav_item__evening is-dropdown"
          @mouseover="hoveredNavigationItem = 'Выпускные'"
          @mouseleave="hoveredNavigationItem = null"
        >
          <nuxt-link
            class="nav_item_link"
            to="/prom/"
            @click.native="menuItemClickHandler('/prom/')"
          >
            Выпускные
          </nuxt-link>
          <div class="nav_dropdown nav_dropdown__alter">
            <div class="nav_dropdown_cols">
              <div class="nav_dropdown_col style">
                <strong class="nav_dropdown_title">Стили</strong>
                <ul class="nav_dropdown_list nav_dropdown_list__slider">
                  <li v-for="style in promStyles" :key="style.id">
                    <nuxt-link
                      :to="`/prom/?style=${style.slug}`"
                      @click.native="menuItemClickHandler(`/prom/?style=${style.slug}`)"
                    >
                      <strong>{{ style.name }}</strong>
                      <ins
                        v-show="style.with_pic"
                        :class="[`nav_style__${style.slug}`, { nav_style__hidden: !style.with_pic }]"
                        class="nav_style"
                      />
                    </nuxt-link>
                  </li>
                </ul>
              </div>
              <div class="nav_dropdown_col brand">
                <strong class="nav_dropdown_title">Топ бренды</strong>
                <ul class="nav_dropdown_list">
                  <div class="left">
                    <li v-for="brand in promBrandsLeft" :key="brand.id">
                      <nuxt-link
                        :to="`/prom/${brand.slug}/`"
                        @click.native="menuItemClickHandler(`/prom/${brand.slug}/`)"
                      >
                        {{ brand.name_eng }}
                      </nuxt-link>
                    </li>
                  </div>
                  <div class="right">
                    <li v-for="brand in promBrandsRight" :key="brand.id">
                      <nuxt-link
                        :to="`/prom/${brand.slug}/`"
                        @click.native="menuItemClickHandler(`/prom/${brand.slug}/`)"
                      >
                        {{ brand.name_eng }}
                      </nuxt-link>
                    </li>
                  </div>
                  <div class="clear" />
                </ul>
              </div>
              <div class="nav_dropdown_col">
                <strong class="nav_dropdown_title">Стоимость</strong>
                <ul class="nav_dropdown_list">
                  <li>
                    <nuxt-link
                      to="/prom/?price_min=10000&price_max=25000"
                      @click.native="menuItemClickHandler('/prom/?price_min=10000&price_max=25000')"
                    >
                      10000 - 25000<span class="ruble">р</span>
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link
                      to="/prom/?price_min=25000&price_max=50000"
                      @click.native="menuItemClickHandler('/prom/?price_min=25000&price_max=50000')"
                    >
                      25000 - 50000<span class="ruble">р</span>
                    </nuxt-link>
                  </li>
                  <li>
                    <nuxt-link
                      to="/prom/?price_min=50000&price_max=1000000"
                      @click.native="menuItemClickHandler('/prom/?price_min=50000&price_max=1000000')"
                    >
                      от 50000<span class="ruble">р</span>
                    </nuxt-link>
                  </li>
                </ul>
              </div>
              <div class="nav_dropdown_col nav_dropdown_col__proceed">
                <nuxt-link
                  to="/prom/"
                  @click.native="menuItemClickHandler('/prom/')"
                >
                  <i /><span>Смотреть<br>полный каталог</span>
                </nuxt-link>
              </div>
            </div>
          </div>
        </li>
        <li
          v-if="accessoriesCategory && accessoriesCategory.active"
          class="nav_item is-dropdown nav_item__wedding"
          :class="{ 'is-hover': hoveredNavigationItem === 'Аксессуары' }"
          @mouseover="hoveredNavigationItem = 'Аксессуары'"
          @mouseleave="hoveredNavigationItem = null"
        >
          <nuxt-link
            class="nav_item_link"
            to="/accessories/"
            @click.native="menuItemClickHandler('/accessories/')"
          >
            Аксессуары
          </nuxt-link>
          <div class="nav_dropdown nav_dropdown__simple">
            <div class="nav_dropdown_cols">
              <div class="nav_dropdown_col style">
                <ul class="nav_dropdown_list">
                  <div class="left">
                    <li v-for="subCategory in accessoriesSubcategoriesLeft" :key="subCategory.id">
                      <nuxt-link
                        :to="`/${subCategory.slug}/`"
                        @click.native="menuItemClickHandler(`/${subCategory.slug}/`)"
                      >
                        {{ subCategory.name }}
                      </nuxt-link>
                    </li>
                  </div>
                  <div class="right">
                    <li v-for="subCategory in accessoriesSubcategoriesRight" :key="subCategory.id">
                      <nuxt-link
                        :to="`/${subCategory.slug}/`"
                        @click.native="menuItemClickHandler(`/${subCategory.slug}/`)"
                      >
                        {{ subCategory.name }}
                      </nuxt-link>
                    </li>
                  </div>
                  <div class="clear" />
                </ul>
              </div>
              <div class="nav_dropdown_col nav_dropdown_col__proceed">
                <nuxt-link
                  to="/accessories/"
                  @click.native="menuItemClickHandler('/accessories/')"
                >
                  <i /><span>Смотреть<br>полный каталог</span>
                </nuxt-link>
              </div>
            </div>
          </div>
        </li>
        <li
          v-if="outerwearCategory && outerwearCategory.active"
          class="nav_item is-dropdown nav_item__wedding"
          :class="{ 'is-hover': hoveredNavigationItem === 'Верхняя одежда' }"
          @mouseover="hoveredNavigationItem = 'Верхняя одежда'"
          @mouseleave="hoveredNavigationItem = null"
        >
          <nuxt-link
            class="nav_item_link"
            to="/outerwear/"
            @click.native="menuItemClickHandler('/outerwear/')"
          >
            Верхняя одежда
          </nuxt-link>
          <div class="nav_dropdown nav_dropdown__simple">
            <div class="nav_dropdown_cols">
              <div class="nav_dropdown_col style">
                <ul class="nav_dropdown_list">
                  <div class="left">
                    <li v-for="subCategory in outwearSubcategoriesLeft" :key="subCategory.id">
                      <nuxt-link
                        :to="`/${subCategory.slug}/`"
                        @click.native="menuItemClickHandler(`/${subCategory.slug}/`)"
                      >
                        {{ subCategory.name }}
                      </nuxt-link>
                    </li>
                  </div>
                  <div class="right">
                    <li v-for="subCategory in outwearSubcategoriesRight" :key="subCategory.id">
                      <nuxt-link
                        :to="`/${subCategory.slug}/`"
                        @click.native="menuItemClickHandler(`/${subCategory.slug}/`)"
                      >
                        {{ subCategory.name }}
                      </nuxt-link>
                    </li>
                  </div>
                  <div class="clear" />
                </ul>
              </div>
              <div class="nav_dropdown_col nav_dropdown_col__proceed">
                <nuxt-link
                  to="/outerwear/"
                  @click.native="menuItemClickHandler('/outerwear/')"
                >
                  <i /><span>Смотреть<br>полный каталог</span>
                </nuxt-link>
              </div>
            </div>
          </div>
        </li>
        <li v-if="boudoirCategory && boudoirCategory.active" class="nav_item">
          <nuxt-link
            class="nav_item_link"
            to="/boudoir/"
            @click.native="menuItemClickHandler('/boudoir/')"
          >
            Будуарные
          </nuxt-link>
        </li>
        <li
          v-if="saleCategory && saleCategory.active"
          class="nav_item is-dropdown nav_item__wedding"
          :class="{ 'is-hover': hoveredNavigationItem === 'Распродажа' }"
          @mouseover="hoveredNavigationItem = 'Распродажа'"
          @mouseleave="hoveredNavigationItem = null"
        >
          <nuxt-link
            class="nav_item_link"
            to="/sale/"
            style="color: orange;"
            @click.native="menuItemClickHandler('/sale/')"
          >
            Распродажа
          </nuxt-link>
          <div class="nav_dropdown nav_dropdown__simple">
            <div class="nav_dropdown_cols">
              <div class="nav_dropdown_col style2">
                <ul class="nav_dropdown_flex nav_dropdown_list">
                  <li v-for="subCategory in saleSubcategoriesLeft" :key="subCategory.id">
                    <nuxt-link
                      :to="`/${subCategory.slug}/`"
                      @click.native="menuItemClickHandler(`/${subCategory.slug}/`)"
                    >
                      {{ subCategory.name }}
                    </nuxt-link>
                  </li>

                  <li v-for="subCategory in saleSubcategoriesRight" :key="subCategory.id">
                    <nuxt-link
                      :to="`/${subCategory.slug}/`"
                      @click.native="menuItemClickHandler(`/${subCategory.slug}/`)"
                    >
                      {{ subCategory.name }}
                    </nuxt-link>
                  </li>
                </ul>
              </div>
              <div class="nav_dropdown_col nav_dropdown_col__proceed">
                <nuxt-link
                  to="/sale/"
                  @click.native="menuItemClickHandler('/sale/')"
                >
                  <i /><span>Смотреть<br>полный каталог</span>
                </nuxt-link>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </nav>
    <a class="fitting-widget-button" @click.prevent="fittingWidgetClick"><span>Записаться на примерку</span></a>
    <div class="ancillary_item ancillary_fav is-active">
      <nuxt-link v-if="hasFavorite" :to="favoriteLink">
        <div class="favorite-button site_s1">
          <div class="counter">
            <ins class="catalog_counter catalog_counter__ancillary">
              {{ favoriteItems.length }}
            </ins>
          </div>
          <div class="text">
            Любимые платья
          </div>
        </div>
      </nuxt-link>
      <a v-else class="js-popup" data-mfp-decor="mfp-product" href="#popup-fav">
        <div class="favorite-button site_s1">
          <div class="counter">
            <ins class="catalog_counter catalog_counter__ancillary">
              0
            </ins>
          </div>
          <div class="text">Любимые платья</div>
        </div>
      </a>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import analyticsMixin from '@/assets/mixins/analytics.mixin'
import { CATEGORY } from '@/assets/js/constants'
import { getBrandsGetterName, getCategoryMapGetters, getStylesGetterName } from '@/helpers/utils'
import Bus from '../../assets/js/bus'

export default {
  name: 'SalonHeader',
  mixins: [analyticsMixin],
  data: () => {
    return {
      hoveredNavigationItem: null,
      searchQuery: ''
    }
  },
  computed: {
    ...mapGetters({
      ...getCategoryMapGetters([CATEGORY.WEDDING, CATEGORY.EVENING, CATEGORY.PROM], { styles: true, brands: true })
    }),
    accessoriesSubcategoriesLeft () {
      return this.accessoriesCategory.children ? this.accessoriesCategory.children.slice(0, Math.ceil(this.accessoriesCategory.children.length / 2)) : []
    },
    accessoriesSubcategoriesRight () {
      return this.accessoriesCategory.children ? this.accessoriesCategory.children.slice(Math.ceil(this.accessoriesCategory.children.length / 2), this.accessoriesCategory.children.length) : []
    },
    outwearSubcategoriesLeft () {
      return this.outerwearCategory.children ? this.outerwearCategory.children.slice(0, Math.ceil(this.outerwearCategory.children.length / 2)) : []
    },
    outwearSubcategoriesRight () {
      return this.outerwearCategory.children ? this.outerwearCategory.children.slice(Math.ceil(this.outerwearCategory.children.length / 2), this.outerwearCategory.children.length) : []
    },
    saleSubcategoriesLeft () {
      return this.saleCategory.children ? this.saleCategory.children.slice(0, Math.ceil(this.saleCategory.children.length / 2)) : []
    },
    saleSubcategoriesRight () {
      return this.saleCategory.children ? this.saleCategory.children.slice(Math.ceil(this.saleCategory.children.length / 2), this.saleCategory.children.length) : []
    },
    weddingCategory () {
      if (!this.categoriesTree) {
        return null
      }
      return this.categoriesTree.find(category => category.slug === CATEGORY.WEDDING)
    },
    eveningCategory () {
      if (!this.categoriesTree) {
        return null
      }
      return this.categoriesTree.find(category => category.slug === CATEGORY.EVENING)
    },
    boudoirCategory () {
      if (!this.categoriesTree) {
        return null
      }
      return this.categoriesTree.find(category => category.slug === CATEGORY.BOUDOIR)
    },
    promCategory () {
      if (!this.categoriesTree) {
        return null
      }
      return this.categoriesTree.find(category => category.slug === CATEGORY.PROM)
    },
    saleCategory () {
      if (!this.categoriesTree) {
        return null
      }
      return this.categoriesTree.find(category => category.slug === CATEGORY.SALE)
    },
    accessoriesCategory () {
      if (!this.categoriesTree) {
        return null
      }
      return this.categoriesTree.find(category => category.slug === CATEGORY.ACCESSORIES)
    },
    outerwearCategory () {
      if (!this.categoriesTree) {
        return null
      }
      return this.categoriesTree.find(category => category.slug === CATEGORY.OUTWEAR)
    },
    categoriesTree () {
      return this.$store.getters.tree
    },
    favoriteLink () {
      return `/favorites/?id=${this.favoriteItems.join('&id=')}`
    },
    favoriteItems () {
      return this.$store.state.favorite.items
    },
    hasFavorite () {
      return this.favoriteItems.length > 0
    },
    weddingBrands () {
      return this[getBrandsGetterName(CATEGORY.WEDDING)]
    },
    eveningBrands () {
      return this[getBrandsGetterName(CATEGORY.EVENING)]
    },
    promBrands () {
      return this[getBrandsGetterName(CATEGORY.PROM)]
    },
    weddingStyles () {
      return this[getStylesGetterName(CATEGORY.WEDDING)]
    },
    eveningStyles () {
      return this[getStylesGetterName(CATEGORY.EVENING)]
    },
    promStyles () {
      return this[getStylesGetterName(CATEGORY.PROM)]
    },
    weddingBrandsLength () {
      return this.weddingBrands.length
    },
    weddingBrandsLeft () {
      return this.weddingBrandsLength ? this.weddingBrands.slice(0, Math.ceil(this.weddingBrandsLength / 2)) : []
    },
    weddingBrandsRight () {
      return this.weddingBrandsLength ? this.weddingBrands.slice(Math.ceil(this.weddingBrandsLength / 2), this.weddingBrandsLength) : []
    },
    eveningBrandsLength () {
      return this.eveningBrands.length
    },
    eveningBrandsLeft () {
      return this.eveningBrandsLength ? this.eveningBrands.slice(0, Math.ceil(this.eveningBrandsLength / 2)) : []
    },
    eveningBrandsRight () {
      return this.eveningBrandsLength ? this.eveningBrands.slice(Math.ceil(this.eveningBrandsLength / 2), this.eveningBrandsLength) : []
    },
    promBrandsLength () {
      return this.promBrands.length
    },
    promBrandsLeft () {
      return this.promBrandsLength ? this.promBrands.slice(0, Math.ceil(this.promBrandsLength / 2)) : []
    },
    promBrandsRight () {
      return this.promBrandsLength ? this.promBrands.slice(Math.ceil(this.promBrandsLength / 2), this.promBrandsLength) : []
    }
  },
  methods: {
    menuItemClickHandler (to) {
      if (this.$route.fullPath !== to) {
        this.hoveredNavigationItem = null
        Bus.$emit('menu-item-click', to)
      }
    },
    searchHandler () {
      if (this.searchQuery && this.searchQuery.length > 1) {
        this.$router.push(`/search/?query=${encodeURIComponent(this.searchQuery)}`)
        this.searchQuery = ''
      }
    },
    fittingWidgetClick (e) {
      Bus.$emit('open-fitting-widget')
    },
    phoneNumberHandler () {
      this.pushEvent({ event: 'phone_click' })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {

  &__first-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    height: 70px;
    position: relative;
  }
}

.header-link {
  display: inline-flex;
  align-items: center;
  font-size: 11px;
  line-height: 30px;
  text-transform: uppercase;
  color: grey;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  .icon {
    display: inline-block;
    margin-right: 8px;
  }
}

.favourite-link {
  font-weight: 100;
  color: #fa809d;
  font-family: 'ProxySB', Arial, Helvetica, sans-serif;
}

.site-logo {
  display: flex;
  height: 56px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.phone-link {
  display: block;
  line-height: 24px;
  font-size: 22px;
  text-decoration: none;
  color: #000;
  font-weight: 700;
}

.work-time-link {
  display: block;
  font-size: 13px;
  margin-top: 5px;
}

// legacy
.header {
  .count {
    display: none;
  }

  .nav_search {
    margin: 0;
  }
}
</style>
