<template>
  <div class="bottom-counters" style="width: 1000px; margin: 0 auto;">
    <callback-popup />
    <buy-popup v-if="$store.state.buyDress" />
    <!--<discount-popup />-->
    <favorite-popup />
    <off-canvas />
  </div>
</template>

<script>
import CallbackPopup from '../popups/CallbackPopup'
import BuyPopup from '../popups/BuyPopup'
import FavoritePopup from '../favorite/FavoritePopup'
import OffCanvas from './OffCanvas'
export default {
  name: 'BottomCounters',
  components: {
    OffCanvas,
    FavoritePopup,
    CallbackPopup,
    BuyPopup
  }
}
</script>

<style></style>
