<template>
  <div class="layout">
    <nuxt />
  </div>
</template>

<style lang="scss"></style>
<script>
import { generateModuleBySlug } from '@/helpers/utils'

export default {
  computed: {
    categories () {
      return this.$store.state.categories
    }
  },
  beforeMount () {
    this.categories.forEach((category) => {
      const module = generateModuleBySlug(category.slug)
      this.$store.registerModule(category.slug, module, { preserveState: true })
    })
  },
  head: {}
}
</script>
