<template>
  <div class="layout_inner">
    <salon-header />

    <section class="vacuum">
      <div class="vacuum_inner">
        <h1 v-if="error.statusCode === 404 && !error.message" class="vacuum_title">
          Тут только лепестки роз
        </h1>
        <h1 v-else-if="error.statusCode === 404 && error.message" class="vacuum_title">
          {{ error.message }}
        </h1>
        <h1 v-else-if="error.statusCode === 500" class="vacuum_title">
          Возникла ошибка, но не беспокойтесь. Мы уже о ней знаем
        </h1>
        <h1 v-else class="vacuum_title" v-text="error.message || 'Что-то пошло не так'" />
        <p>
          Можете вернуться на
          <nuxt-link to="/">
            главную страницу
          </nuxt-link>
          <br>или перейти в
          <nuxt-link to="/wedding/">
            каталог свадебных
          </nuxt-link>
          ,
          <nuxt-link to="/evening/">
            вечерних
          </nuxt-link>
          <br>или
          <nuxt-link to="/boudoir/">
            будуарных платьев
          </nuxt-link>
        </p>
      </div>
    </section>
    <div class="decor decor__2 css-sprite-2" />
    <div class="decor decor__5 css-sprite-5" />
    <div class="decor decor__7 css-sprite-7" />
    <div class="decor decor__8 css-sprite-8" />
    <div class="decor decor__9 css-sprite-9" />
    <div class="decor decor__11" />
    <bottom-counters />
  </div>
</template>

<script>
import SalonHeader from '../components/layout/SalonHeader'
import { app, initApp } from '../assets/js/common'
import BottomCounters from '../components/layout/BottomCounters'

const pageClasses = 'page-loveforever page-vacuum'

export default {
  name: 'Error',
  components: { SalonHeader, BottomCounters },
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  mounted () {
    if (app.prepared) {
      setTimeout(() => {
        initApp(pageClasses, this.$store)
      }, 0)
    }
  },
  head: {
    bodyAttrs: {
      class: pageClasses
    }
  },
  layout: 'default'
}
</script>

<style lang="scss"></style>
