import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _888c1908 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _591a0fdc = () => interopDefault(import('../pages/browser.vue' /* webpackChunkName: "pages/browser" */))
const _4b44644d = () => interopDefault(import('../pages/catalog.vue' /* webpackChunkName: "pages/catalog" */))
const _9cef2262 = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _e6111c20 = () => interopDefault(import('../pages/delivery.vue' /* webpackChunkName: "pages/delivery" */))
const _16afeeea = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _3e2e4f88 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _4d31b162 = () => interopDefault(import('../pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _4d90caa4 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _0e3dfa2e = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _31839f06 = () => interopDefault(import('../pages/articles/_article.vue' /* webpackChunkName: "pages/articles/_article" */))
const _7829ccde = () => interopDefault(import('../pages/_category.vue' /* webpackChunkName: "pages/_category" */))
const _d45da514 = () => interopDefault(import('../pages/_category/_brand.vue' /* webpackChunkName: "pages/_category/_brand" */))
const _05f43f65 = () => interopDefault(import('../components/pages/ItemPage.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/articles/",
    component: _888c1908,
    pathToRegexpOptions: {"strict":true},
    name: "articles"
  }, {
    path: "/browser/",
    component: _591a0fdc,
    pathToRegexpOptions: {"strict":true},
    name: "browser"
  }, {
    path: "/catalog/",
    component: _4b44644d,
    pathToRegexpOptions: {"strict":true},
    name: "catalog"
  }, {
    path: "/contacts/",
    component: _9cef2262,
    pathToRegexpOptions: {"strict":true},
    name: "contacts"
  }, {
    path: "/delivery/",
    component: _e6111c20,
    pathToRegexpOptions: {"strict":true},
    name: "delivery"
  }, {
    path: "/favorites/",
    component: _16afeeea,
    pathToRegexpOptions: {"strict":true},
    name: "favorites"
  }, {
    path: "/privacy/",
    component: _3e2e4f88,
    pathToRegexpOptions: {"strict":true},
    name: "privacy"
  }, {
    path: "/reviews/",
    component: _4d31b162,
    pathToRegexpOptions: {"strict":true},
    name: "reviews"
  }, {
    path: "/search/",
    component: _4d90caa4,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/sitemap/",
    component: _0e3dfa2e,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap"
  }, {
    path: "/",
    component: _482400f4,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/articles/:article/",
    component: _31839f06,
    pathToRegexpOptions: {"strict":true},
    name: "articles-article"
  }, {
    path: "/:category/",
    component: _7829ccde,
    pathToRegexpOptions: {"strict":true},
    name: "category",
    children: [{
      path: ":brand?/",
      component: _d45da514,
      pathToRegexpOptions: {"strict":true},
      name: "category-brand"
    }]
  }, {
    path: "/:category/:brand/:item/",
    component: _05f43f65,
    name: "item-page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
